import { useContext, useEffect, useState } from "react";
import { Typography, Box, Button, IconButton } from "@mui/material";
import Integrated from "./Integrated";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useNavigate } from "react-router-dom";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import Skeleton from "@mui/material/Skeleton";
const AutomaticView = () => {
    const navigate = useNavigate();
    const workctx = useContext(WorkspaceContext);
    const [isIntegrated, setIsIntegrated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const handleIntegration = () => {
        navigate("/dashboard/integration");
    };

    const fetchIntegrationApp = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchIntegrationApp/${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.data) {
                    setIsIntegrated(true);
                    setIsLoading(false);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Fetch error: ", error);
            });
    };
    useEffect(() => {
        fetchIntegrationApp();
    }, []);
    return (
        <>
            {isIntegrated && !isLoading ? (
                <Integrated />
            ) : isLoading ? (
                <div style={{ maxWidth: 600, margin: "auto", padding: "20px", textAlign: "center" }}>
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={10} width="80%" />
                </div>
            ) : (
                <Box
                    sx={{
                        maxWidth: 600,
                        margin: "auto",
                        padding: "20px",
                        textAlign: "center",
                    }}
                >
                    <IconButton disableRipple sx={{ fontSize: 60, color: "black" }}>
                        <MailOutlineIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                        Get Started with Email Auditing
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                        To start automatically auditing your campaigns, integrate your campaign tool. Once integrated,
                        you can view detailed reports for all your campaigns right here.
                    </Typography>

                    <Button
                        variant="contained"
                        color="info"
                        sx={{ mt: 3, textTransform: "none", px: 4 }}
                        onClick={handleIntegration}
                    >
                        Integrate Campaign Tool
                    </Button>
                </Box>
            )}
        </>
    );
};
export default AutomaticView;
