import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    TextField,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Typography,
    IconButton,
} from "@mui/material";
import HtmlPreview from "./HtmlPreview"; // Import the preview component
import PreviewIcon from "@mui/icons-material/Visibility";
import CodeIcon from "@mui/icons-material/Code";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const AuditDialog = ({ open, senderList, onClose, onSubmit }) => {
    const [clientData, setClientData] = useState({
        campaignName: "",
        from: [],
        subject: "",
        body: "",
    });
    console.log(senderList, "list");
    const [errors, setErrors] = useState({
        campaignName: "",
        from: "",
        subject: "",
        body: "",
    });
    const [showPreview, setShowPreview] = useState(false);

    const isSubmitDisabled = !clientData.subject.trim() || !clientData.body.trim() || clientData.from.length === 0;

    const validateField = (name, value) => {
        if (name === "campaignName" && value.trim() === "") return "Campaign Name is required";
        if (name === "subject" && value.trim() === "") return "Subject is required";
        if (name === "body" && value.trim() === "") return "Body is required";
        if (name === "from" && value.length === 0) return "At least one email must be selected";
        return "";
    };

    const handleChange = (data, name) => {
        const error = validateField(name, data);
        setErrors((prev) => ({ ...prev, [name]: error }));

        setClientData((prev) => ({ ...prev, [name]: data }));
    };

    const handleSubmit = () => {
        const errors = {
            campaignName: validateField("campaignName", clientData.campaignName),
            subject: validateField("subject", clientData.subject),
            body: validateField("body", clientData.body),
            from: validateField("from", clientData.from),
        };

        if (Object.values(errors).some((error) => error)) {
            setErrors(errors); // Show errors if any
        } else {
            onSubmit(clientData); // Submit the data
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="domain-list-dialog" fullWidth maxWidth="md">
            <DialogContent>
                <Box marginBottom={2}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h5" fontWeight={600}>
                            Run Your Test Audit
                        </Typography>
                        <Typography>
                            Fill in the details below to perform a comprehensive audit of your email.
                        </Typography>
                    </div>

                    <TextField
                        fullWidth
                        label="Campaign"
                        value={clientData.campaignName}
                        onChange={(e) => handleChange(e.target.value, "campaignName")}
                        margin="normal"
                        variant="outlined"
                        error={!!errors.campaignName}
                        helperText={errors.campaignName}
                    />
                </Box>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="from-label">Sender Email</InputLabel>
                    <Select
                        labelId="from-label"
                        multiple
                        value={clientData.from}
                        onChange={(e) => handleChange(e.target.value, "from")}
                        input={<OutlinedInput label="Sender Email" />}
                        renderValue={(selected) => selected.join(", ")} // Display selected emails as a comma-separated string
                    >
                        {senderList && senderList.length > 0 ? (
                            senderList.map((list) => (
                                <MenuItem key={list.id} value={list.mail}>
                                    <Checkbox checked={clientData.from.includes(list.mail)} />
                                    <ListItemText primary={list.mail} />
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>
                                <ListItemText primary="No Data Found" />
                            </MenuItem>
                        )}
                    </Select>
                    {errors.from && <Box color="error.main">{errors.from}</Box>}
                </FormControl>
                <Typography variant="h6" mt={3}>
                    Email Template
                </Typography>
                <TextField
                    fullWidth
                    label="Enter email subject here"
                    value={clientData.subject}
                    onChange={(e) => handleChange(e.target.value, "subject")}
                    margin="normal"
                    variant="outlined"
                    error={!!errors.subject}
                    helperText={errors.subject}
                />
                <Box
                    sx={{
                        position: "relative",
                        backgroundColor: showPreview ? "#f9f9f9" : "white",
                    }}
                >
                    {!showPreview ? (
                        <TextField
                            fullWidth
                            label="Enter email message here"
                            value={clientData.body}
                            onChange={(e) => handleChange(e.target.value, "body")}
                            margin="normal"
                            variant="outlined"
                            multiline
                            rows={10}
                            error={!!errors.body}
                            helperText={errors.body}
                        />
                    ) : clientData.body.trim() ? (
                        <HtmlPreview htmlContent={clientData.body} />
                    ) : (
                        <Box mt={2} color="text.secondary">
                            No content to preview.
                        </Box>
                    )}
                    <IconButton
                        onClick={() => setShowPreview(!showPreview)}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            zIndex: 1,
                            color: showPreview ? "primary.main" : "text.secondary",
                        }}
                    >
                        {showPreview ? <VisibilityOffIcon /> : <CodeIcon />}
                    </IconButton>
                </Box>

                <Box display="flex" justifyContent="end">
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
                        Run Test Aduit
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AuditDialog;
