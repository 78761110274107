import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import "../DomainReputation/domainreputation.css";
import { Box, Button, Typography, Container } from "@mui/material";
import { Grid } from "@mui/material";
import DomainName from "./DomainName";

const DomainReputation = () => {
    const [step, setStep] = useState(0);
    const [selectedDomain, setSelectedDomain] = useState("");
    const [txt, setTxt] = useState("");
    const [addDomain, setAddDomain] = useState(false);
    const [triggerRender, setTriggerRender] = useState(false);
    const handleNextStep = (domain, txt) => {
        setAddDomain(true);
        setStep(step + 1);
        setSelectedDomain(domain);
        setTxt(txt);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const handleClose = () => {
        setAddDomain(false);
        setStep(0);
        setTriggerRender((prev) => !prev);
    };
    const handleCloseStep1 = () => {
        setAddDomain(false);
        setStep(0);
    };
    const renderStepContent = () => {
        switch (step) {
            case 1:
                return <Step1 onNext={handleNextStep} onClose={handleCloseStep1} />;
            case 2:
                return (
                    <Step2
                        onNext={handleNextStep}
                        onPrevious={handlePreviousStep}
                        onClose={handleClose}
                        domain={selectedDomain}
                        txt={txt}
                    />
                );
            case 3:
                return <Step3 onPrevious={handlePreviousStep} onClose={handleClose} domain={selectedDomain} />;
            default:
                return null;
        }
    };

    return (
        <Box>
            <Box sx={{ textAlign: "center" }}>
                <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>Domain/IP Reputation</span>
                <br />
                <span
                    style={{
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                    }}
                >
                    Are you seen as trustworthy by email providers, or is there room for improvement? Our tool provides
                    a comprehensive overview empowering you to grasp how your reputation influences the success of your
                    email campaigns
                </span>
            </Box>
            <br />

            <Grid container spacing={2} sx={{ marginTop: "10px", alignItems: "center" }}>
                <Grid item xs={12} md={8}>
                    <Typography
                        sx={{
                            color: "#101828",
                            fontSize: "20px",
                            fontWeight: 600,
                            lineHeight: "28px",
                        }}
                    >
                        <b>Domain Reputation</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: "right" }}>
                    <Button
                        variant="contained"
                        onClick={handleNextStep}
                        sx={{
                            textTransform: "none",
                            minWidth: "150px",
                            fontSize: "15px",
                            fontWeight: 700,
                        }}
                        color="primary"
                    >
                        Add Domain
                    </Button>
                </Grid>
            </Grid>
            {addDomain && renderStepContent()}
            <DomainName key={triggerRender} />
        </Box>
    );
};

export default DomainReputation;
