import React from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

export default function CustomTabs({ activeTab, onTabChange }) {
    const tabStyles = {
        tab: {
            flex: 1,
            border: "1px solid #000",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: activeTab === "email" ? "bold" : "normal",
            color: "#000",
            "&:not(:last-child)": {
                borderRight: "1px solid #000",
            },
            "&.Mui-selected": {
                color: "#0000FF",
                backgroundColor: "#f0f0f0",
            },
        },
        tabs: {
            display: "flex",
            border: "1px solid #000",
            borderRadius: "4px",
            marginTop: "20px",
            marginBottom: "20px",
        },
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Email Test Audit
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Audit Your Emails for Better Performance.
            </Typography>

            <Tabs
                value={activeTab}
                onChange={(event, newValue) => onTabChange(newValue)}
                sx={tabStyles.tabs}
                variant="fullWidth"
                TabIndicatorProps={{ style: { display: "none" } }} // Removes underline
            >
                <Tab label="From an Email Account" value="email" sx={tabStyles.tab} />
                <Tab label="Automatic Test" value="automatic" sx={tabStyles.tab} />
                <Tab
                    label="From Marketing Platforms"
                    value="marketing"
                    sx={{ ...tabStyles.tab, borderRight: "none" }}
                />
            </Tabs>
        </Box>
    );
}
