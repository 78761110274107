import React from "react";
import PropTypes from "prop-types";

const HtmlPreview = ({ htmlContent }) => {
    return (
        <div
            style={{
                padding: "10px",
                border: "1px solid black",
                borderRadius: "4px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                maxWidth: "100%",
                maxHeight: "300px", // Optional: Limit height for large content
                overflowY: "auto", // Optional: Enable vertical scrolling
                padding: "10px",
                borderRadius: "4px",
                marginTop: "10px",
                marginBottom: "10px",
            }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
    );
};

HtmlPreview.propTypes = {
    htmlContent: PropTypes.string.isRequired,
};

export default HtmlPreview;
