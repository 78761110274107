export default function SetupFetchInterceptor(handleExpiredPlan) {
    const originalFetch = window.fetch;
    window.fetch = async (url, options) => {
        try {
            const response = await originalFetch(url, options);
            console.log(response, "interceptor");

            // Clone the response to allow reading the body multiple times
            const clonedResponse = response.clone();

            let responseData = null;
            try {
                responseData = await clonedResponse.json();
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }

            if (response.status === 403 && responseData && responseData.isExpired) {
                console.log("403 status received & isExpired");
                handleExpiredPlan();
            }

            return response;
        } catch (error) {
            console.error("An error occurred while making the request:", error);
            return Promise.reject("An error occurred while making the request");
        }
    };
}
