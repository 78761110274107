import React from "react";
import { Box, Typography, Button, Grid, Card, CardContent, LinearProgress, Divider } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import TableComponent from "../common/TableComponent";
import { useNavigate } from "react-router-dom";
const formatNumber = (value) => {
    if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(1) + "M"; // Convert to millions
    } else if (value >= 1_000) {
        return (value / 1_000).toFixed(1) + "k"; // Convert to thousands
    } else {
        return value.toString(); // Return as is
    }
};
const Dashboard = ({ dashboardData }) => {
    const navigate = useNavigate();
    const categories = dashboardData.spamCountAtDate ? dashboardData.spamCountAtDate.map((item) => item.date) : []; // Dates for xAxis
    const inboxData = dashboardData.spamCountAtDate ? dashboardData.spamCountAtDate.map((item) => item.inboxCount) : []; // Inbox counts
    const spamData = dashboardData.spamCountAtDate ? dashboardData.spamCountAtDate.map((item) => item.spamCount) : []; // Spam counts

    const options = {
        chart: {
            type: "column", // Bar chart
        },
        title: {
            text: "",
        },
        xAxis: {
            categories, // Dates on x-axis
            title: {
                text: "Date",
            },
            labels: {
                rotation: 0, // Rotate labels for better readability
                formatter: function () {
                    return Highcharts.dateFormat("%e %b", new Date(this.value).getTime()); // Format to "5 Dec"
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Counts",
            },
        },
        tooltip: {
            shared: true,
            pointFormat: "<b>{series.name}:</b> {point.y}<br/>",
        },
        series: [
            {
                name: "Inbox Count",
                data: inboxData,
                color: "#4caf50", // Green color
            },
            {
                name: "Spam Count",
                data: spamData,
                color: "#f44336", // Red color
            },
        ],
        credits: {
            enabled: false, // Disable Highcharts.com watermark
        },
    };
    console.log(dashboardData, "providers");
    const deliverabilityDataCategories = dashboardData.deliveryByProviders
        ? Object.keys(dashboardData.deliveryByProviders).map((key) => {
              if (key === "googleEsp") return "Google ESP";
              if (key === "outlookEsp") return "Outlook ESP";
              if (key === "otherEsp") return "Other ESP";
              return key;
          })
        : [];
    const deliveryInboxData = dashboardData.deliveryByProviders
        ? Object.values(dashboardData.deliveryByProviders).map((item) => item.inboxCount)
        : [];
    const deliverySpamData = dashboardData.deliveryByProviders
        ? Object.values(dashboardData.deliveryByProviders).map((item) => item.spamCount)
        : [];

    const deliverityDataOptions = {
        chart: {
            type: "bar", // Horizontal stacked bars
        },
        title: {
            text: "",
        },
        xAxis: {
            categories: deliverabilityDataCategories, // Custom categories for y-axis
            title: {
                text: "",
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Total Count",
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: "bold",
                },
                formatter: function () {
                    return this.total;
                },
            },
        },
        tooltip: {
            shared: true,
            pointFormat: "<b>{series.name}:</b> {point.y}<br/>",
        },
        plotOptions: {
            series: {
                stacking: "normal", // Enables stacking
                dataLabels: {
                    enabled: true, // Shows value inside bars
                    formatter: function () {
                        return this.y > 0 ? this.y : null;
                    },
                },
            },
        },
        series: [
            {
                name: "Inbox Count",
                data: deliveryInboxData,
                color: "#4caf50", // Green
            },
            {
                name: "Spam Count",
                data: deliverySpamData,
                color: "#f44336", // Red
            },
        ],
        credits: {
            enabled: false, // Disable Highcharts.com watermark
        },
    };

    const tranformedEmailLogsData = dashboardData.recentlyAudited
        ? dashboardData.recentlyAudited.map((row) => ({
              email: row.sender,
              inboxCount: row?.inboxCount || 0,
              spamCount: row?.spamCount || 0,
              createdAt: new Date(row.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-"),
          }))
        : [];
    const emailCampaignColumns = [
        { field: "email", headerName: "Email Campaigns", sortable: true },
        { field: "inboxCount", headerName: "Inbox", sortable: true },
        { field: "spamCount", headerName: "Spam", sortable: true },
        { field: "createdAt", headerName: "Date", sortable: true },
    ];
    const tranformedData = dashboardData.domainReputation
        ? dashboardData.domainReputation.map((row) => ({
              domain: row.domain,
              status: row.domainReputation,
              createdAt: row.create_time,
          }))
        : [];
    const domainReputationColumns = [
        { field: "domain", headerName: "Domains Reputation", sortable: true },
        { field: "status", headerName: "Status", sortable: true },
        { field: "createdAt", headerName: "Date", sortable: true },
    ];
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <Typography fontWeight="bold" fontSize="36px" color="primary">
                                {formatNumber(dashboardData?.totalAudited || 0)}
                            </Typography>
                            <Typography>Total Audits Run</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <Typography fontWeight="bold" fontSize="36px" color="error">
                                {formatNumber(dashboardData?.spamCount || 0)}
                            </Typography>
                            <Typography>Spam Issues</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            <Typography variant="h6">Integration</Typography>
                            <Typography variant="body2">
                                To start automatically auditing your campaigns, integrate your campaign tool.
                            </Typography>
                            <Box mt="auto">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/dashboard/integration")}
                                >
                                    Integrate
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            <Typography variant="h6">Start Your Test Audit!</Typography>
                            <Typography variant="body2">Start Auditing Emails Sent from Any Email Account</Typography>
                            <Box mt="auto">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/dashboard/email-audit-v1")}
                                >
                                    Run Test Audit
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>{" "}
            <Box sx={{ paddingTop: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Card sx={{ height: "100%" }}>
                            <CardContent sx={{ height: "100%" }}>
                                <Typography variant="h6">Spam Watch Status</Typography>
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card sx={{ height: "100%" }}>
                            <CardContent
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: "100%",
                                }}
                            >
                                <Typography variant="h6">Deliverability by Providers</Typography>
                                <HighchartsReact highcharts={Highcharts} options={deliverityDataOptions} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginTop: 3 }}>
                <Card>
                    <CardContent>
                        {" "}
                        <Typography variant="h6" sx={{ m: 1 }}>
                            Recently Audited
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <TableComponent
                                    rows={tranformedEmailLogsData}
                                    columns={emailCampaignColumns}
                                    usePaper={false}
                                />
                            </Grid>{" "}
                            <Grid item xs={6}>
                                <TableComponent
                                    rows={tranformedData}
                                    usePaper={false}
                                    columns={domainReputationColumns}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default Dashboard;
