import { Snackbar, Alert } from "@mui/material";

const SnackBar = ({ open, message, type, onClose }) => {
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        if (onClose) {
            onClose();
        }
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert onClose={handleClose} variant="filled" severity={type} sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackBar;
