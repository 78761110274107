import React, { useState } from "react";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import AutomaticView from "./Automatic";
import ManualView from "./Manual";
import TestAudit from "./TestAudit";
import { useLocation } from "react-router-dom";
import Report from "./ReportStatus";
export default function EmailAuditing() {
    const location = useLocation();
    const state = location.state;
    console.log(state, "emailAuditState");
    const [mode, setMode] = useState("Automatic");
    const [runTestAudit, setRunTestAudit] = useState(state ? true : false);
    const [key, setKey] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [clientData, setClientData] = useState({});
    const handleModeChange = (event, newMode) => {
        if (newMode !== null) {
            setMode(newMode);
        }
    };
    const handleAudit = () => {
        setRunTestAudit(true);
    };
    const handleClose = () => {
        setRunTestAudit(false);
        setKey((prev) => !prev);
    };
    const handleAuditSubmit = (clientData) => {
        setClientData(clientData);
        setShowReport(true);
    };
    const handleBack = () => {
        setShowReport(false);
        handleClose();
    };
    return (
        <>
            {!showReport ? (
                <Box
                    sx={{
                        textAlign: "center",
                        boxSizing: "border-box",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 3,
                        }}
                    >
                        <ToggleButtonGroup
                            value={mode}
                            exclusive
                            onChange={handleModeChange}
                            aria-label="mode selection"
                        >
                            <ToggleButton
                                value="Automatic"
                                aria-label="automatic"
                                sx={{
                                    textTransform: "none",
                                    backgroundColor: mode === "Automatic" ? "#0288D1 !important" : "white",
                                    color: mode === "Automatic" ? "white !important" : "black",
                                }}
                            >
                                Automatic
                            </ToggleButton>
                            <ToggleButton
                                value="Manual"
                                aria-label="manual"
                                sx={{
                                    textTransform: "none",
                                    backgroundColor: mode === "Manual" ? "#0288D1 !important" : "white",
                                    color: mode === "Manual" ? "white !important" : "black",
                                }}
                            >
                                Manual
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <Button variant="contained" color="primary" size="small" onClick={handleAudit}>
                            Run Test Audit
                        </Button>
                    </Box>
                    {mode === "Automatic" ? <AutomaticView key={key} /> : <ManualView />}
                    {runTestAudit && (
                        <TestAudit
                            onClose={handleClose}
                            onboardState={state}
                            handleAuditSubmit={(clientData) => handleAuditSubmit(clientData)}
                        />
                    )}
                </Box>
            ) : (
                <Report handleBack={handleBack} clientData={clientData} />
            )}
        </>
    );
}
