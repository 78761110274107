import Tabs from "./Tabs";
import { useEffect, useState } from "react";
import EmailAccountHome from "./EmailAccounts/index";
import AutomaticTest from "./AutomaticTest";
import MarketingPlatform from "./MarketingPlatforms/InitialPlatform";
import { useLocation, useNavigate } from "react-router-dom";
const EmailAuditV1 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    const [firstparam, secondParam] = tab ? tab.split(",") : [null, null];
    const getSecondParam = secondParam ? secondParam.split("=")[1] : null;
    console.log(firstparam, getSecondParam, "initalTab, campaignName");
    const [activeTab, setActiveTab] = useState(tab ? firstparam : "email");
    const handleTabChange = (newTab) => {
        navigate(`/dashboard/email-audit-v1?tab=${newTab}`);
        setActiveTab(newTab);
    };

    console.log(activeTab, "activeTabs", firstparam);
    return (
        <div>
            <div>
                <Tabs activeTab={activeTab} onTabChange={handleTabChange} />
            </div>
            {activeTab === "email" && <EmailAccountHome secondParam={getSecondParam} />}
            {activeTab === "automatic" && <AutomaticTest secondParam={getSecondParam} />}
            {activeTab === "marketing" && <MarketingPlatform />}
        </div>
    );
};

export default EmailAuditV1;
