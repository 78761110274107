import React, { useState, useEffect, useRef } from "react";
import inbox from "../../img/images/inbox.png";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Skeleton from "@mui/material/Skeleton";
import "./Audit.css";
import { Card, CardContent, Avatar, CardActionArea, Button } from "@material-ui/core";
import DNSOverviewChart from "../manualAudit/AuditOverview/DNSOverViewChart";
import { Box, Typography, Grid, List, ListItem, ListItemText, Paper, IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import _ from "underscore";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate, Link } from "react-router-dom";
import ActionComponent from "./ActionComponent";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { serviceProviders } from "../common/ServiceProvider";
ChartJS.register(ArcElement, Tooltip, Legend);

const CheckReport = ({ handleBack }) => {
    const contentRef = useRef(null);
    const [overflow, setOverflow] = useState("hidden");
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({
        subject: "Subject 1",
        originalContent: "Hi this is my content",
    });
    const [overview, setOverview] = useState([]);
    const [domainBlacklist, setDomainBlacklist] = useState([]);
    const [ipBlacklist, setIpBlacklist] = useState([]);
    const [lastReportDate, setLastReportDate] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [overallScore, setOverallScore] = useState(0);
    const [selectedPanel, setSelectedPanel] = useState("Overview");
    const [iptCounts, setIptCounts] = useState({});
    const [dnsData, setDnsData] = useState({});
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft -= 150;
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += 150;
        }
    };
    const espCount = [];
    for (let key in iptCounts) {
        serviceProviders.forEach((provider) => {
            if (key === provider.service && iptCounts[key] > 0) {
                const found = espCount.find((data) => data.icons === provider.icon);
                found
                    ? (found[provider.title] = iptCounts[key])
                    : espCount.push({ [provider.title]: iptCounts[key], icons: provider.icon });
            }
        });
    }
    const dummyData = [
        {
            icon: <RocketLaunchIcon />,
            value: "--",
            description: "Per day volume",
        },
        {
            icon: <ChatBubbleIcon />,
            value: "--",
            description: "Per day warmup volume",
        },
    ];
    const handlePanelSelect = (panel) => {
        setSelectedPanel(panel);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const queryParams = new URLSearchParams(window.location.search);
    let fromDate = queryParams.get("fromDate");
    let toDate = queryParams.get("toDate");
    let domainName = queryParams.get("domainName");

    useEffect(() => {
        fetchStoreAuditAutomationData();
    }, []);
    const fetchStoreAuditAutomationData = async () => {
        try {
            // const response = await fetch(
            //     `${process.env.REACT_APP_SERVER_URL}/fetchAuditAutomationData?fromDate=${fromDate}&toDate=${toDate}&domainName=${domainName}`,
            //     {
            //         method: "GET",
            //         headers: {
            //             Accept: "application/json",
            //             "Content-Type": "application/json",
            //         },
            //         credentials: "include",
            //     }
            // );
            // const json = await response.json();
            // if (!json.success) {
            //     setSnackbarSeverity("error");
            //     setSnackbarMessage(json.data.message);
            //     setSnackbarOpen(true);
            //     navigate("dashboard/spreadsheet");
            //     return;
            // }
            let json = {
                success: true,
                message: "Successfully fetched data",
                audit_automation: [
                    {
                        id: 1378,
                        spf: true,
                        dkim: true,
                        dmarc: true,
                        domain_name: "xemailverify.com",
                        domain_blacklist: {
                            listed: [
                                {
                                    status: "listed",
                                    zone: "black.uribl.com",
                                    url: "uribl.com",
                                },
                                {
                                    status: "listed",
                                    zone: "multi.uribl.com",
                                    url: "uribl.com",
                                },
                                {
                                    status: "listed",
                                    zone: "xs.surbl.org",
                                    url: "surbl.org",
                                },
                                {
                                    status: "listed",
                                    zone: "bl.emailbasura.org",
                                    url: "emailbasura.org",
                                },
                                {
                                    status: "listed",
                                    zone: "red.uribl.com",
                                    url: "uribl.com",
                                },
                                {
                                    status: "listed",
                                    zone: "grey.uribl.com",
                                    url: "uribl.com",
                                },
                                {
                                    status: "listed",
                                    zone: "rhsbl.mailhosts.org",
                                    url: "mailhosts.org",
                                },
                                {
                                    status: "listed",
                                    zone: "xs.surbl.org",
                                    url: "surbl.org",
                                },
                                {
                                    status: "listed",
                                    zone: "dnsbl.burnt-tech.com",
                                    url: "burnt-tech.com",
                                },
                                {
                                    status: "listed",
                                    zone: "rbl.megarbl.net",
                                    url: "megarbl.net",
                                },
                                {
                                    status: "listed",
                                    zone: "shortlist.mailhosts.org",
                                    url: "mailhosts.org",
                                },
                                {
                                    status: "listed",
                                    zone: "bl.score.senderscore.com",
                                    url: "score.senderscore.com",
                                },
                                {
                                    status: "listed",
                                    zone: "singlebl.spamgrouper.com",
                                    url: "spamgrouper.com",
                                },
                                {
                                    status: "listed",
                                    zone: "ipbl.mailhosts.org",
                                    url: "mailhosts.org",
                                },
                            ],
                        },
                        ip_blacklist: {
                            listed: [
                                {
                                    status: "listed",
                                    zone: "grey.uribl.com",
                                    url: "uribl.com",
                                },
                                {
                                    status: "listed",
                                    zone: "black.uribl.com",
                                    url: "uribl.com",
                                },
                                {
                                    status: "listed",
                                    zone: "xs.surbl.org",
                                    url: "surbl.org",
                                },
                                {
                                    status: "listed",
                                    zone: "dbl.spamhaus.org",
                                    url: "spamhaus.org",
                                },
                                {
                                    status: "listed",
                                    zone: "bl.emailbasura.org",
                                    url: "emailbasura.org",
                                },
                                {
                                    status: "listed",
                                    zone: "multi.uribl.com",
                                    url: "uribl.com",
                                },
                                {
                                    status: "listed",
                                    zone: "dnsbl.burnt-tech.com",
                                    url: "burnt-tech.com",
                                },
                                {
                                    status: "listed",
                                    zone: "rhsbl.mailhosts.org",
                                    url: "mailhosts.org",
                                },
                                {
                                    status: "listed",
                                    zone: "xs.surbl.org",
                                    url: "surbl.org",
                                },
                                {
                                    status: "listed",
                                    zone: "singlebl.spamgrouper.com",
                                    url: "spamgrouper.com",
                                },
                                {
                                    status: "listed",
                                    zone: "red.uribl.com",
                                    url: "uribl.com",
                                },
                                {
                                    status: "listed",
                                    zone: "rbl.megarbl.net",
                                    url: "megarbl.net",
                                },
                                {
                                    status: "listed",
                                    zone: "shortlist.mailhosts.org",
                                    url: "mailhosts.org",
                                },
                                {
                                    status: "listed",
                                    zone: "bl.score.senderscore.com",
                                    url: "score.senderscore.com",
                                },
                                {
                                    status: "listed",
                                    zone: "ipbl.mailhosts.org",
                                    url: "mailhosts.org",
                                },
                            ],
                        },
                        domain_send_mail_result: {
                            label: "inbox",
                        },
                        fromDate: "2024-09-12T00:00:00.000Z",
                        toDate: "2024-09-18T00:00:00.000Z",
                        domainScore: 97,
                        ipScore: 96,
                        automationDomainScoreResult: 4.83,
                        automationIpScoreResult: 4.82,
                        dnsAuditResultScore: 5,
                        templateScore: 1,
                        overAllScore: 78.25,
                        status: "Pass",
                        progress: 100,
                        randomString: "sljfldj88",
                        iptCounts:
                            '{"inbox":1,"spam":0,"avgGoogleInbox":1,"avgGoogleSpam":0,"avgGsuiteInbox":0,"avgGsuiteSpam":0,"avgOutlookInbox":0,"avgOutlookSpam":0}',
                        createdAt: "2024-09-19T06:27:44.000Z",
                        updatedAt: "2024-09-19T06:29:55.000Z",
                        scores: [
                            {
                                domainScore: 97,
                                ipScore: 96,
                                createdAt: "2024-09-19T06:27:44.000Z",
                            },
                            {
                                domainScore: null,
                                ipScore: null,
                                createdAt: "2024-09-19T06:27:14.000Z",
                            },
                            {
                                domainScore: 96,
                                ipScore: 96,
                                createdAt: "2024-09-19T06:24:09.000Z",
                            },
                            {
                                domainScore: 96,
                                ipScore: 96,
                                createdAt: "2024-09-19T06:15:45.000Z",
                            },
                            {
                                domainScore: null,
                                ipScore: null,
                                createdAt: "2024-09-19T06:15:26.000Z",
                            },
                        ],
                        templates: [
                            {
                                reasons: [
                                    {
                                        isSpammycontentWithoutExternalLink: null,
                                        isSpammycontentWithoutSignature: null,
                                        isSpammycontentWithoutImages: null,
                                    },
                                ],
                                isSpammy: null,
                                refinedTemplate: {
                                    content: "Yes",
                                    subject: "Important meeting",
                                },
                                originalTemplate: {
                                    content:
                                        '<html><head>\r\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></head><body><div>Hi, we have an important meeting tonight.</div><div><br></div><div>Cheers,</div><div>Sayank Jha</div><img src="https://link.inproxalberta1.net/signature/images/92oj-Fwsh3SJ_XIO9yK2d.png" alt="line"></body></html>',
                                    subject: "Important meeting",
                                },
                            },
                            {
                                reasons: [
                                    {
                                        isSpammycontentWithoutExternalLink: null,
                                        isSpammycontentWithoutSignature: null,
                                        isSpammycontentWithoutImages: null,
                                    },
                                ],
                                isSpammy: null,
                                refinedTemplate: {
                                    content: "Yes",
                                    subject: "Movie Night ?",
                                },
                                originalTemplate: {
                                    content:
                                        '<html><head>\r\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></head><body><div>Hi, are you available today for a movie night at my place.&nbsp;</div><div><br></div><div>Cheers,</div><div>Sayank Jha</div><img src="https://link.inproxalberta1.net/signature/images/gFUkMAFLB4XfQ85Bm9A4d.png" alt="line"></body></html>',
                                    subject: "Movie Night ?",
                                },
                            },
                        ],
                    },
                ],
            };
            const auditAutomation = json.audit_automation[0];
            let keys = Object.keys(auditAutomation);
            setLastReportDate(moment(auditAutomation.createdAt).format("lll"));
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] === "domain_blacklist") {
                    if (auditAutomation[keys[i]]?.listed.length > 0) {
                        const blackList = auditAutomation[keys[i]].listed.map((zone) => ({
                            name: zone.zone,
                            url: zone.url,
                        }));
                        setDomainBlacklist(blackList);
                    }
                    continue;
                } else if (keys[i] === "ip_blacklist") {
                    if (auditAutomation[keys[i]]?.listed.length > 0) {
                        const iplistDomains = auditAutomation[keys[i]].listed
                            .filter((zone) => ({ name: zone.url }))
                            .map((zone) => ({ name: zone.zone, url: zone.url }));
                        setIpBlacklist(iplistDomains);
                    }
                    continue;
                } else if (keys[i] === "templates") {
                    let templatesData = auditAutomation[keys[i]];
                    let templateList = [];
                    if (templatesData.length && templatesData.length > 0) {
                        for (let j = 0; j < templatesData.length; j++) {
                            let arr = [];
                            for (let k = 0; k < templatesData[j].reasons.length; k++) {
                                arr.push({
                                    "Without Externallink": templatesData[j].reasons[k]
                                        .isSpammycontentWithoutExternalLink
                                        ? true
                                        : false,
                                    "Without Signature": templatesData[j].reasons[k].isSpammycontentWithoutSignature
                                        ? true
                                        : false,
                                    "Without Images": templatesData[j].reasons[k].isSpammycontentWithoutImages
                                        ? true
                                        : false,
                                });
                            }
                            templateList.push({
                                id: j,
                                subject: templatesData[j].originalTemplate.subject
                                    ? templatesData[j].originalTemplate.subject
                                    : "Default Subject",
                                originalContent: templatesData[j].originalTemplate.content,
                                refinedContent: templatesData[j].refinedTemplate.content,
                                name: `Template - ${j + 1}`,
                                reasons: arr,
                            });
                        }
                        setTemplates(templateList);
                        setSelectedTemplate(templateList[0]);
                    }
                } else if (keys[i] === "overAllScore") {
                    setOverallScore(Math.floor(auditAutomation[keys[i]]));
                } else if (keys[i] === "iptCounts") {
                    const parse = JSON.parse(auditAutomation.iptCounts);
                    setOverview([
                        { title: "Inbox Count", count: parse.inbox },
                        { title: "Spam Count", count: parse.spam },
                    ]);
                    setIptCounts(parse);
                }
            }
            setDnsData({ SPF: auditAutomation.spf, DMARC: auditAutomation.dmarc, DKIM: auditAutomation.dkim });
        } catch (error) {
            console.error(`\n Error in fetchStoreAuditAutomationData --> ${error}`);
        }
    };
    const handleRegenerateAudit = () => {
        navigate(
            `/dashboard/audit-report?fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}&domainName=${domainName}`
        );
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
    };
    useEffect(() => {
        if (espCount.length > 2) {
            setOverflow("auto");
        } else {
            setOverflow("hidden");
        }
    }, [espCount]);
    return (
        <Box>
            <>
                <Box>
                    <Grid container alignItems="center" justifyContent="space-between">
                        {/* Breadcrumbs on the left */}
                        <Grid item>
                            <Button variant="outlined" color="primary" onClick={handleBack}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {Object.keys(dnsData).length <= 0 ? (
                    <Box className="container_box">
                        <Grid container spacing={3}>
                            {/* Score Card Section */}
                            <Grid item xs={12} md={3}>
                                <Paper
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 2,
                                        padding: "20px",
                                        border: "1px solid #f1f1f1",
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        boxShadow: "none !important",
                                    }}
                                >
                                    <Box
                                        style={{
                                            width: "180px",
                                            height: "180px",
                                            position: "relative",
                                        }}
                                    >
                                        <Skeleton variant="circular" width={180} height={180} />
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Box style={{ marginTop: "10px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <List>
                                        {[...Array(4)].map((_, index) => (
                                            <ListItem key={index}>
                                                <Skeleton variant="text" width="100%" height={80} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={2}>
                                        {[...Array(2)].map((_, index) => (
                                            <Grid item xs={12} md={6} key={index}>
                                                <Paper
                                                    style={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        height: "300px", // Set max height for scrollable section
                                                        overflowY: "auto", // Enable vertical scrolling
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Skeleton variant="text" width={200} height={40} />

                                                    <List>
                                                        {[...Array(3)].map((_, index) => (
                                                            <ListItem key={index}>
                                                                <Skeleton variant="text" width="100%" height={80} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                ) : (
                    <Box className="container_box">
                        <Grid container spacing={2} columns={16}>
                            <Grid item xs={3} sm={8} md={4}>
                                <Card
                                    style={{
                                        width: "100%",
                                        mb: 2,
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                        color: "white",
                                        backgroundColor: "#0884e2",
                                    }}
                                >
                                    <CardContent style={{ textAlign: "center" }}>
                                        <Typography variant="h4">High Score</Typography>
                                        <Typography variant="h2">{overallScore}</Typography>
                                        <Typography variant="subtitle1">out of 100</Typography>
                                    </CardContent>
                                    <Typography
                                        style={{
                                            margin: "10px",
                                            padding: "10px",
                                            backgroundColor: "#9EC4DF",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        The score is calculated directly from these metrics: Inbox rate, Domain health,
                                        and IP insights
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={3} sm={8} md={7}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    {overview.map((data, index) => (
                                        <Grid item md={6} key={index}>
                                            <Card
                                                key={index}
                                                style={{
                                                    "&:hover": { backgroundColor: "#F7F7F9" },
                                                    borderRadius: "8px",
                                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                    mb: 2,
                                                }}
                                            >
                                                <CardActionArea>
                                                    <CardContent>
                                                        <Typography variant="h6" gutterBottom textAlign="center">
                                                            <img
                                                                src={inbox}
                                                                alt=""
                                                                style={{
                                                                    height: "31px",
                                                                    width: "32px",
                                                                }}
                                                            />{" "}
                                                            {data.title}
                                                        </Typography>
                                                        <Typography
                                                            variant="h5"
                                                            style={
                                                                data.title === "Inbox Count"
                                                                    ? { color: "#536dfe" } // Blue color for Inbox Count
                                                                    : { color: "#e91e63" } // Pink color for other cases
                                                            }
                                                            textAlign="center"
                                                        >
                                                            {data.count}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Box
                                    ref={contentRef}
                                    style={{
                                        maxHeight: 160,
                                        overflowY: overflow,
                                        padding: 2,
                                        // Ensure scrollbar is visible when needed
                                        "&::-webkit-scrollbar": {
                                            width: "8px",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                            borderRadius: "8px",
                                        },
                                        msOverflowStyle: "auto", // IE and Edge
                                        scrollbarWidth: "auto", // Firefox
                                        margin: "10px",
                                    }}
                                >
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        {espCount.map((data, index) => (
                                            <Grid item md={espCount[index + 1] || index % 2 !== 0 ? 6 : 12} key={index}>
                                                <Card
                                                    key={index}
                                                    sx={{
                                                        "&:hover": { backgroundColor: "#F7F7F9" },
                                                        borderRadius: "8px",
                                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                        mb: 2,
                                                    }}
                                                >
                                                    <CardActionArea>
                                                        <CardContent>
                                                            <Typography variant="h6" gutterBottom textAlign="center">
                                                                <img
                                                                    src={data.icons}
                                                                    alt=""
                                                                    style={{
                                                                        height: "31px",
                                                                        width: "32px",
                                                                    }}
                                                                />{" "}
                                                                ESP Count
                                                            </Typography>
                                                            <Typography variant="h5" textAlign="center">
                                                                <span style={{ color: "#536dfe" }}>
                                                                    {data.inbox ? data.inbox : 0}
                                                                </span>
                                                                /
                                                                <span style={{ color: "#e91e63" }}>
                                                                    {data.spam ? data.spam : 0}
                                                                </span>
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >
                                                                <div>
                                                                    <Box
                                                                        sx={{
                                                                            bgcolor: "#536dfe",
                                                                            width: 10,
                                                                            height: 10,
                                                                        }}
                                                                    />
                                                                    <span>Inbox</span>
                                                                </div>
                                                                <div>
                                                                    <Box
                                                                        sx={{
                                                                            bgcolor: "#e91e63",
                                                                            width: 10,
                                                                            height: 10,
                                                                        }}
                                                                    />
                                                                    Spam
                                                                </div>
                                                            </Box>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={3} sm={8} md={4}>
                                {dummyData.map((data, index) => (
                                    <Card
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: "120px",
                                            padding: "20px",
                                            marginBottom: "20px",
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            "&:hover": { backgroundColor: "#F7F7F9" },
                                        }}
                                    >
                                        <Avatar style={{ bgcolor: "#E0E0E0", mr: 2 }}>{data.icon}</Avatar>
                                        <CardContent style={{ flexGrow: 1, textAlign: "center" }}>
                                            <Typography variant="h5" component="div">
                                                {data.value}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                {data.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Grid>
                        <Box style={{ marginTop: "10px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <ActionComponent onSelect={handlePanelSelect} />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    {selectedPanel === "Blacklist" && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        maxHeight: "280px",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Domain Blacklist
                                                    </Typography>
                                                    <List>
                                                        {domainBlacklist.length === 0 ? (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary="No data found"
                                                                    sx={{ textAlign: "center" }}
                                                                />
                                                            </ListItem>
                                                        ) : (
                                                            domainBlacklist.map((data, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                    sx={{
                                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "5px",
                                                                        marginBottom: "2px",
                                                                        "&:hover": {
                                                                            backgroundColor: "#e0f2f1",
                                                                        },
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={data.name}
                                                                        sx={{ textAlign: "center" }}
                                                                    />
                                                                    <a
                                                                        className="custom-button"
                                                                        href={`http://${data.url}/`} // External URL
                                                                        target="_blank" // Opens the link in a new tab
                                                                        rel="noopener noreferrer" // Security best practice when opening in a new tab
                                                                        style={{
                                                                            color: "#fff",
                                                                            textDecoration: "none",
                                                                        }}
                                                                    >
                                                                        Delist
                                                                    </a>
                                                                </ListItem>
                                                            ))
                                                        )}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        maxHeight: "280px",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        IP Blacklist
                                                    </Typography>
                                                    <List>
                                                        {ipBlacklist.length === 0 ? (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary="No data found"
                                                                    sx={{ textAlign: "center" }}
                                                                />
                                                            </ListItem>
                                                        ) : (
                                                            ipBlacklist.map((data, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                    sx={{
                                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "5px",
                                                                        marginBottom: "2px",
                                                                        "&:hover": {
                                                                            backgroundColor: "#e0f2f1",
                                                                        },
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={data.name}
                                                                        sx={{ textAlign: "center" }}
                                                                    />
                                                                    <a
                                                                        className="custom-button"
                                                                        href={`http://${data.url}/`} // External URL
                                                                        target="_blank" // Opens the link in a new tab
                                                                        rel="noopener noreferrer" // Security best practice when opening in a new tab
                                                                        style={{
                                                                            color: "#fff",
                                                                            textDecoration: "none",
                                                                        }}
                                                                    >
                                                                        Delist
                                                                    </a>
                                                                </ListItem>
                                                            ))
                                                        )}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {selectedPanel === "DNS Reputation" && (
                                        <Box>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#f9f9f9",
                                                            padding: "15px",
                                                            borderRadius: "8px",
                                                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                                            marginBottom: "20px",
                                                        }}
                                                    >
                                                        {Object.keys(dnsData).map((data, key) => {
                                                            return (
                                                                <p
                                                                    key={key}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        padding: "8px 0",
                                                                        borderBottom: "1px solid #e0e0e0",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            color: "#333",
                                                                        }}
                                                                    >
                                                                        {data.toUpperCase()}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                dnsData[data] === true
                                                                                    ? "#4CAF50"
                                                                                    : "#F44336", // Green for Pass, Red for Fail
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        {dnsData[data] ? "PASS" : "FAIL"}
                                                                    </span>
                                                                </p>
                                                            );
                                                        })}
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Box>
                                    )}
                                    {selectedPanel === "Overview" && <DNSOverviewChart inboxSpamCount={overview} />}
                                    {selectedPanel === "TemplateList" && (
                                        <Paper
                                            style={{
                                                width: "850px",
                                                padding: "20px",
                                                border: "1px solid #f1f1f1",
                                                borderRadius: "5px",
                                                backgroundColor: "#fff",
                                                boxShadow: "none !important",
                                            }}
                                        >
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                {templates.length > 5 && (
                                                    <IconButton onClick={scrollLeft} style={{ marginTop: "-25px" }}>
                                                        <ArrowBackIosIcon style={{ height: "20px" }} />
                                                    </IconButton>
                                                )}
                                                <Box
                                                    ref={scrollRef}
                                                    style={{
                                                        display: "flex",
                                                        overflowX: "hidden",
                                                        pb: 2,
                                                        width: "calc(100% - 64px)",
                                                    }}
                                                >
                                                    {templates.map((template) => {
                                                        return (
                                                            <ListItem
                                                                key={template.id}
                                                                className="template-card"
                                                                onClick={() => handleTemplateSelect(template)}
                                                                style={{
                                                                    backgroundColor: "white",
                                                                    "&:hover": {
                                                                        backgroundColor: "#e0f2f1",
                                                                    },
                                                                    flex: "0 0 auto",
                                                                    width: "120px",
                                                                    m: 1,
                                                                    p: 2,
                                                                    borderRadius: "5px",
                                                                    cursor: "pointer",
                                                                    border: "1px solid #ccc",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <ListItemText
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Typography>
                                                                        {template.subject.slice(0, 20).concat("....")}
                                                                    </Typography>
                                                                </ListItemText>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </Box>
                                                {templates.length > 5 && (
                                                    <IconButton onClick={scrollRight} style={{ marginTop: "-25px" }}>
                                                        <ArrowForwardIosIcon style={{ height: "20px" }} />
                                                    </IconButton>
                                                )}
                                            </Box>
                                            <Paper style={{ p: 2, display: "flex" }}>
                                                <Box>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            borderRadius: "5px",
                                                            padding: "10px",
                                                            marginBottom: "20px",
                                                            backgroundColor: "white",
                                                            alignItems: "center",
                                                            height: "40px",
                                                            gap: 2,
                                                            width: "550px", // Set width to 550px
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: "#24a0ed",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            Subject:
                                                        </Typography>
                                                        {selectedTemplate && (
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    padding: "5px 10px",
                                                                    borderRadius: "5px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    flexGrow: 1, // Allows the text to take up remaining space
                                                                }}
                                                            >
                                                                {selectedTemplate.subject}
                                                            </Typography>
                                                        )}
                                                        <ContentCopyIcon
                                                            style={{ marginLeft: "auto", cursor: "pointer" }}
                                                        />
                                                    </Box>

                                                    {/* Body Display Box */}
                                                    <Box
                                                        style={{
                                                            borderRadius: "5px",
                                                            padding: "10px",
                                                            backgroundColor: "white",
                                                            alignItems: "center",
                                                            overflow: "hidden",
                                                            width: "550px", // Set width to 550px
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: "300px",
                                                                overflowY: "auto",
                                                                padding: "10px",
                                                                backgroundColor: "#f9f9f9",
                                                                borderRadius: "5px",
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: selectedTemplate.originalContent,
                                                            }}
                                                        ></div>
                                                        <ContentCopyIcon
                                                            style={{
                                                                marginTop: "10px",
                                                                cursor: "pointer",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box
                                                    style={{
                                                        backgroundColor: "#e0f2f1",
                                                        width: "350px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Typography variant="h6" style={{ mb: 2 }}>
                                                        Spam Analysis
                                                    </Typography>
                                                    <Typography style={{ mb: 2 }}>
                                                        {selectedTemplate.refinedContent}
                                                    </Typography>
                                                </Box>
                                            </Paper>
                                        </Paper>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </>

            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} style={{ width: "100%" }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default CheckReport;
