import React, { useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Typography, Paper, Skeleton } from "@mui/material";
import Instantly from "../../img/images/instant.png";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBar from "../common/SnackBar";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const InstantlyAPIForm = ({ handleRedirect }) => {
    const workctx = useContext(WorkspaceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [bccData, setBccData] = useState([]);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const handleConnect = () => {
        setIsLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/createAppIntegraion`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspaceId: workctx.activeWorkspace,
                api_key: apiKey,
                name: "instantly",
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    handleRedirect();
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message || data.msg);
                }
                setIsLoading(false);
            });
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchBccForIntegrationApp?workspaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    console.log(data, "bccData");
                    setBccData(data.data);
                } else {
                    setErrorMessage(data.message);
                    setErrorSnackbarOpen(true);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setSkeletonLoader(false));
    }, []);
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                padding: 2,
            }}
        >
            <Paper
                sx={{
                    padding: 3,
                }}
            >
                {skeletonLoader ? (
                    <>
                        <Skeleton variant="text" width={200} height={40} sx={{ mb: 2 }} />
                        <Skeleton variant="text" width={400} height={20} sx={{ mb: 2 }} />
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mb: 2 }} />
                    </>
                ) : (
                    <>
                        <Typography
                            variant="h5"
                            component="div"
                            color="primary"
                            sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
                        >
                            <img src={Instantly} alt="Instantly Logo" style={{ width: 24, height: 24 }} />
                            Instantly
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Enter your instantly account API key here to audit campaigns
                        </Typography>
                        <TextField
                            label="Enter API key"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            value={apiKey}
                            onChange={(e) => setApiKey(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ display: "block" }}
                            disabled={isLoading}
                            onClick={() => handleConnect()}
                        >
                            {isLoading ? <CircularProgress color="inherit" /> : "Connect"}
                        </Button>
                        <Box sx={{ mt: 2, textAlign: "left" }}>
                            <Typography variant="subtitle2" gutterBottom>
                                Steps to find Instantly API key:
                            </Typography>
                            <Typography variant="body2">
                                •{" "}
                                <a href="https://app.instantly.ai/auth/login" target="_blank">
                                    Login
                                </a>{" "}
                                to your Instantly application.
                                <br />• Go to settings and then the integration tab (
                                <a href="https://app.instantly.ai/app/settings/integrations" target="_blank">
                                    URL
                                </a>
                                ).
                                <br />• In Instantly API card click on Generate new API key, copy the new generated API
                                key, and paste it above.
                            </Typography>
                            {bccData?.length > 0 && (
                                <Typography variant="body2">
                                    • Add this{" "}
                                    {bccData
                                        .map(({ email }, index) => (
                                            <Typography key={index} component="span" fontWeight={"bold"}>
                                                {email}
                                            </Typography>
                                        ))
                                        .reduce((prev, curr) => [prev, ", ", curr])}{" "}
                                    in your campaign
                                </Typography>
                            )}
                        </Box>
                    </>
                )}
            </Paper>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};

export default InstantlyAPIForm;
