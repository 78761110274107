import { Box, Button, Typography, IconButton } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const MarketingPlatform = () => {
    return (
        <Box
            sx={{
                maxWidth: 800,
                margin: "auto",
                padding: "20px",
                textAlign: "center",
            }}
        >
            <IconButton disableRipple sx={{ fontSize: 80, color: "black" }}>
                <MailOutlineIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                Connect Your Marketing Platform
            </Typography>
            <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                Easily audit campaigns from your marketing platforms by connecting your tools.
            </Typography>

            <Button variant="contained" color="info" sx={{ mt: 3, textTransform: "none", px: 4 }}>
                Connect
            </Button>
        </Box>
    );
};

export default MarketingPlatform;
