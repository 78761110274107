import React, { useEffect } from "react";
import { Button, Box, Dialog, DialogActions, Typography } from "@mui/material";
import SnackBar from "../common/SnackBar";

const Step3 = ({ domain, onClose }) => {
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");

    useEffect(() => {
        setSuccessSnackbarOpen(true);
        setSuccessMessage("Domain verified successfully");
    }, []);

    const handleDone = () => {
        onClose();
    };

    return (
        <Dialog
            open
            onClose={handleDone}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "400px",
                    maxWidth: "90%", // Responsive width
                    minHeight: "300px",
                    borderRadius: "20px",
                },
            }}
        >
            <Box
                sx={{
                    borderBottom: "1px solid #f1f1f1",
                    padding: "15px",
                    backgroundColor: "#f9f9f9",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "20px",
                        fontWeight: 600,
                        textAlign: "center",
                    }}
                >
                    Step 3/3 – Verification Page
                </Typography>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "18px",
                        textAlign: "center",
                        mb: 3,
                        color: "text.primary",
                    }}
                >
                    {domain} is Verified.
                </Typography>
            </Box>

            <DialogActions sx={{ justifyContent: "center", padding: "16px" }}>
                <Button variant="contained" color="success" size="large" onClick={handleDone}>
                    Done
                </Button>
            </DialogActions>

            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </Dialog>
    );
};

export default Step3;
