import { useState, useEffect, useRef, useContext } from "react";
import { serviceProviders } from "../../common/ServiceProvider";
import inbox from "../../../img/images/inbox.png";
import TemplatePanel from "./TemplatePanel";
import {
    Button,
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Paper,
    Skeleton,
    List,
    ListItem,
    ListItemText,
    CardActionArea,
    Avatar,
} from "@mui/material";
import SnackBar from "../../common/SnackBar";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DNSOverviewChart from "./DNSOverviewChart";
import ActionComponent from "./ActionComponent";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import { AuthContext } from "../../Provider/AuthProvider";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Report = ({ handleBack, clientData }) => {
    const contentRef = useRef(null);
    const intervalRef = useRef(null);
    const workctx = useContext(WorkspaceContext);
    const authCtx = useContext(AuthContext);
    const [dnsData, setDNSData] = useState([]);
    const [ipListedZones, setIPListedZones] = useState([]);
    const [domainListedZones, setDomainListedZones] = useState([]);
    const [selectedPanel, setSelectedPanel] = useState("Overview");
    const [iptCounts, setIptCounts] = useState({});
    const [score, setScore] = useState(null);
    const [overview, setOverview] = useState([]);
    const [loader, setLoader] = useState(false);
    const [templateContent, setTemplateContent] = useState({});
    const [spamCheck, setSpamCheck] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [overflow, setOverflow] = useState("hidden");
    const [progressStatus, setProgressStatus] = useState("Processing...");
    const [progress, setProgress] = useState(0);
    const [randomStringForProcess, setRandomStringForProcess] = useState("");
    const progressRef = useRef(progress);
    const processRef = useRef(randomStringForProcess);
    const espCount = [];
    const handlePanelSelect = (panel) => {
        setSelectedPanel(panel);
    };

    for (let key in iptCounts) {
        serviceProviders.forEach((provider) => {
            if (key === provider.service && iptCounts[key] > 0) {
                const found = espCount.find((data) => data.icons === provider.icon);
                found
                    ? (found[provider.title] = iptCounts[key])
                    : espCount.push({ [provider.title]: iptCounts[key], icons: provider.icon });
            }
        });
    }
    const handleComponentData = async (results) => {
        await Promise.all([
            handleProcessResult(results.auditResults[0].data),
            handleTemplate(results.contentAudit.data),
        ]);
    };
    const handleProcessResult = (results) => {
        const data = results.data;
        console.log(results.data, "result");
        setScore(results?.score || 0);
        const dnsRecord = data.find((item) => item.title === "DNS Records Check");
        setDNSData(dnsRecord?.data || null);
        const ipReputation = data.find((item) => item.title === "IP Reputation Check");
        setIPListedZones(ipReputation?.data?.ipListedZones?.listed || []);
        const domainReputation = data.find((item) => item.title === "Domain Reputation Check");
        setDomainListedZones(domainReputation?.data?.domainListedZones?.listed || []);
    };
    const handleTemplate = (data) => {
        const iptCounts = data.find((item) => item.title === "iptCounts");
        setOverview([
            { title: "Inbox Count", count: iptCounts.data.overallInboxCount },
            { title: "Spam Count", count: iptCounts.data.overallSpamCount },
        ]);
        const templateContentData = data.find((item) => item.title === "originalContent")?.data;
        setTemplateContent(templateContentData);
        const spamCheckData = data.find((item) => item.title === "modifiedContent")?.data;
        setSpamCheck(spamCheckData);
        setIptCounts(iptCounts.data);
        console.log(data);
    };
    const generateRandomString = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const createAuditAutomationData = async () => {
        var randomString;
        randomString = generateRandomString(10);
        setRandomStringForProcess(randomString);
        try {
            setLoader(true);

            const response = await fetch(`${REACT_APP_SERVER_URL}/performAuditWithSafeContent`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    senderEmails: clientData.from,
                    processCode: randomString,
                    loginUser: authCtx.user.email,
                    auditEmailSubject: clientData.subject,
                    auditEmailBody: clientData.body,
                    workSpaceId: workctx.activeWorkspace,
                }),
            });

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }
            const data = await response.json();
            handleComponentData(data);
        } catch (error) {
            console.error("Error creating audit automation data:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        createAuditAutomationData();
    }, []);
    useEffect(() => {
        if (randomStringForProcess) {
            const initialTimeout = setTimeout(() => {
                getAuditProgress();

                intervalRef.current = setInterval(() => {
                    getAuditProgress();
                }, 5000);
            }, 10000);

            return () => {
                clearTimeout(initialTimeout);
                clearInterval(intervalRef.current);
            };
        }
    }, [randomStringForProcess]);

    const getAuditProgress = async () => {
        try {
            await fetch(
                `${process.env.REACT_APP_SERVER_URL}/manualAuditProcessStatusCheck?code=${randomStringForProcess}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (
                        data.success &&
                        data.data.processPercentage !== undefined &&
                        data.data.processPercentage !== null
                    ) {
                        setProgress(data.data.processPercentage);
                        setProgressStatus(data.data.processStatus);
                    }

                    if (data.data.processPercentage >= 100 || data.data.processStatus === "Fail") {
                        clearInterval(intervalRef.current);
                        setProgress(data.data.processPercentage);
                        setLoader(false);
                    }
                });
        } catch (error) {
            if (error.message.includes("Failed to fetch")) {
                console.error("Connection refused or network error");
                setLoader(false);
                setSnackbarMessage("Connection refused or network error");
                setSnackbarOpen(true);
                setTimeout(() => handleBack(), 2000);
            } else {
                console.error("Error fetching audit progress:", error.message);
            }
        }
    };
    const dummyData = [
        {
            icon: <RocketLaunchIcon />,
            value: "--",
            description: "Per day volume",
        },
        {
            icon: <ChatBubbleIcon />,
            value: "--",
            description: "Per day warmup volume",
        },
    ];
    useEffect(() => {
        if (espCount.length > 2) {
            setOverflow("auto");
        } else {
            setOverflow("hidden");
        }
    }, [espCount]);
    function LinearProgressWithLabel(props) {
        return (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        {...props}
                        style={{
                            height: 10, // **Height of the progress bar** (changed)

                            borderRadius: 5, // **Rounded corners** (changed)
                            "& .MuiLinearProgress-bar": {
                                borderRadius: 5, // **Rounded corners of the progress bar fill** (changed)
                            },
                            color: "#fff",
                        }}
                    />
                </Box>
                <Box style={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };

    return (
        <Box>
            <>
                <Grid container alignItems="center" justifyContent="space-between">
                    {/* Breadcrumbs on the left */}
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={handleBack}>
                            Back
                        </Button>
                    </Grid>
                </Grid>
                {loader || Object.keys(dnsData).length <= 0 ? (
                    <div>
                        <Box style={{ backgroundColor: "#F5F5F5", padding: "20px", borderRadius: "10px" }}>
                            <Box>
                                <Typography>{progressStatus}</Typography>
                                <Typography>Please visit again in 2 to 3 minutes</Typography>
                            </Box>
                            <LinearProgressWithLabel value={progress} />
                        </Box>
                        <Box className="container_box">
                            <Grid container spacing={3}>
                                {/* Score Card Section */}
                                <Grid item xs={12} md={3}>
                                    <Paper
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: 2,
                                            padding: "20px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            boxShadow: "none",
                                        }}
                                    >
                                        <Box
                                            style={{
                                                width: "180px",
                                                height: "180px",
                                                position: "relative",
                                            }}
                                        >
                                            <Skeleton variant="circular" width={180} height={180} />
                                        </Box>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={3}>
                                        {Array.from({ length: 2 }).map((_, index) => (
                                            <Grid item xs={12} sm={6} md={6} key={index}>
                                                <Card
                                                    sx={{
                                                        borderRadius: "8px",
                                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                        mb: 2,
                                                    }}
                                                >
                                                    <CardActionArea>
                                                        <CardContent>
                                                            {/* Simulating the icon */}
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    mb: 2,
                                                                }}
                                                            >
                                                                <Skeleton variant="circular" width={32} height={31} />
                                                            </Box>

                                                            {/* Simulating the title */}
                                                            <Skeleton
                                                                variant="text"
                                                                width="60%"
                                                                height={28}
                                                                sx={{ mx: "auto" }}
                                                            />

                                                            {/* Simulating the inbox/spam counts */}
                                                            <Skeleton
                                                                variant="text"
                                                                width="40%"
                                                                height={36}
                                                                sx={{ mx: "auto", my: 2 }}
                                                            />

                                                            {/* Simulating the colored indicators */}
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    mt: 2,
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: 1,
                                                                    }}
                                                                >
                                                                    <Skeleton
                                                                        variant="rectangular"
                                                                        width={10}
                                                                        height={10}
                                                                    />
                                                                    <Skeleton variant="text" width={50} height={20} />
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: 1,
                                                                    }}
                                                                >
                                                                    <Skeleton
                                                                        variant="rectangular"
                                                                        width={10}
                                                                        height={10}
                                                                    />
                                                                    <Skeleton variant="text" width={50} height={20} />
                                                                </Box>
                                                            </Box>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Box style={{ marginTop: "10px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <List>
                                            {[...Array(4)].map((_, index) => (
                                                <ListItem key={index}>
                                                    <Skeleton variant="text" width="100%" height={80} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Grid container spacing={2}>
                                            {[...Array(2)].map((_, index) => (
                                                <Grid item xs={12} md={6} key={index}>
                                                    <Paper
                                                        style={{
                                                            padding: "20px",
                                                            border: "1px solid #f1f1f1",
                                                            borderRadius: "5px",
                                                            backgroundColor: "#fff",
                                                            height: "300px", // Set max height for scrollable section
                                                            overflowY: "auto", // Enable vertical scrolling
                                                            boxShadow: "none !important",
                                                        }}
                                                    >
                                                        <Skeleton variant="text" width={200} height={40} />

                                                        <List>
                                                            {[...Array(3)].map((_, index) => (
                                                                <ListItem key={index}>
                                                                    <Skeleton variant="text" width="100%" height={80} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Paper>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </div>
                ) : (
                    <Box className="container_box">
                        <Grid container spacing={2} columns={16}>
                            <Grid item xs={3} sm={8} md={4}>
                                <Card
                                    style={{
                                        width: "100%",
                                        mb: 2,
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                        color: "white",
                                        backgroundColor: "#0884e2",
                                    }}
                                >
                                    <CardContent style={{ textAlign: "center" }}>
                                        <Typography variant="h4">High Score</Typography>
                                        <Typography variant="h2">{score}</Typography>
                                        <Typography variant="subtitle1">out of 100</Typography>
                                    </CardContent>
                                    <Typography
                                        style={{
                                            margin: "10px",
                                            padding: "10px",
                                            backgroundColor: "#9EC4DF",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        The score is calculated directly from these metrics: Inbox rate, Domain health,
                                        and IP insights
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={3} sm={8} md={8}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    {overview.map((data, index) => (
                                        <Grid item md={6} key={index}>
                                            <Card
                                                key={index}
                                                style={{
                                                    "&:hover": { backgroundColor: "#F7F7F9" },
                                                    borderRadius: "8px",
                                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                    mb: 2,
                                                }}
                                            >
                                                <CardActionArea>
                                                    <CardContent>
                                                        <Typography variant="h6" gutterBottom textAlign="center">
                                                            <img
                                                                src={inbox}
                                                                alt=""
                                                                style={{
                                                                    height: "31px",
                                                                    width: "32px",
                                                                }}
                                                            />{" "}
                                                            {data.title}
                                                        </Typography>
                                                        <Typography
                                                            variant="h5"
                                                            style={
                                                                data.title === "Inbox Count"
                                                                    ? { color: "#536dfe" } // Blue color for Inbox Count
                                                                    : { color: "#e91e63" } // Pink color for other cases
                                                            }
                                                            textAlign="center"
                                                        >
                                                            {data.count}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Box
                                    ref={contentRef}
                                    style={{
                                        maxHeight: 160,
                                        overflowY: overflow,
                                        padding: 2,
                                        // Ensure scrollbar is visible when needed
                                        "&::-webkit-scrollbar": {
                                            width: "8px",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                            borderRadius: "8px",
                                        },
                                        msOverflowStyle: "auto", // IE and Edge
                                        scrollbarWidth: "auto", // Firefox
                                        marginTop: "10px",
                                    }}
                                >
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        {espCount.map((data, index) => (
                                            <Grid item md={espCount[index + 1] || index % 2 !== 0 ? 6 : 12} key={index}>
                                                <Card
                                                    key={index}
                                                    sx={{
                                                        "&:hover": { backgroundColor: "#F7F7F9" },
                                                        borderRadius: "8px",
                                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                        mb: 2,
                                                    }}
                                                >
                                                    <CardActionArea>
                                                        <CardContent>
                                                            <Typography variant="h6" gutterBottom textAlign="center">
                                                                <img
                                                                    src={data.icons}
                                                                    alt=""
                                                                    style={{
                                                                        height: "31px",
                                                                        width: "32px",
                                                                    }}
                                                                />{" "}
                                                                ESP Count
                                                            </Typography>
                                                            <Typography variant="h5" textAlign="center">
                                                                <span style={{ color: "#536dfe" }}>
                                                                    {data.inbox ? data.inbox : 0}
                                                                </span>
                                                                /
                                                                <span style={{ color: "#e91e63" }}>
                                                                    {data.spam ? data.spam : 0}
                                                                </span>
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >
                                                                <div>
                                                                    <Box
                                                                        sx={{
                                                                            bgcolor: "#536dfe",
                                                                            width: 10,
                                                                            height: 10,
                                                                        }}
                                                                    />
                                                                    <span>Inbox</span>
                                                                </div>
                                                                <div>
                                                                    <Box
                                                                        sx={{
                                                                            bgcolor: "#e91e63",
                                                                            width: 10,
                                                                            height: 10,
                                                                        }}
                                                                    />
                                                                    Spam
                                                                </div>
                                                            </Box>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={3} sm={8} md={4}>
                                {dummyData.map((data, index) => (
                                    <Card
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: "120px",
                                            padding: "20px",
                                            marginBottom: "20px",
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            "&:hover": { backgroundColor: "#F7F7F9" },
                                        }}
                                    >
                                        <Avatar style={{ bgcolor: "#E0E0E0", mr: 2 }}>{data.icon}</Avatar>
                                        <CardContent style={{ flexGrow: 1, textAlign: "center" }}>
                                            <Typography variant="h5" component="div">
                                                {data.value}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                {data.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Grid>
                        <Box style={{ marginTop: "10px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <ActionComponent onSelect={handlePanelSelect} />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    {selectedPanel === "Blacklist" && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        maxHeight: "280px",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Domain Blacklist
                                                    </Typography>
                                                    <List>
                                                        {domainListedZones.length === 0 ? (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary="No data found"
                                                                    sx={{ textAlign: "center" }}
                                                                />
                                                            </ListItem>
                                                        ) : (
                                                            domainListedZones.map((data, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                    sx={{
                                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "5px",
                                                                        marginBottom: "2px",
                                                                        "&:hover": {
                                                                            backgroundColor: "#e0f2f1",
                                                                        },
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={data.zone}
                                                                        sx={{ textAlign: "center" }}
                                                                    />
                                                                    <a
                                                                        className="custom-button"
                                                                        href={`http://${data.url}/`} // External URL
                                                                        target="_blank" // Opens the link in a new tab
                                                                        rel="noopener noreferrer" // Security best practice when opening in a new tab
                                                                        style={{
                                                                            color: "#fff",
                                                                            textDecoration: "none",
                                                                        }}
                                                                    >
                                                                        Delist
                                                                    </a>
                                                                </ListItem>
                                                            ))
                                                        )}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        maxHeight: "280px",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        IP Blacklist
                                                    </Typography>
                                                    <List>
                                                        {ipListedZones.length === 0 ? (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary="No data found"
                                                                    sx={{ textAlign: "center" }}
                                                                />
                                                            </ListItem>
                                                        ) : (
                                                            ipListedZones.map((data, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                    sx={{
                                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "5px",
                                                                        marginBottom: "2px",
                                                                        "&:hover": {
                                                                            backgroundColor: "#e0f2f1",
                                                                        },
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={data.zone}
                                                                        sx={{ textAlign: "center" }}
                                                                    />
                                                                    <a
                                                                        className="custom-button"
                                                                        href={`http://${data.url}/`} // External URL
                                                                        target="_blank" // Opens the link in a new tab
                                                                        rel="noopener noreferrer" // Security best practice when opening in a new tab
                                                                        style={{
                                                                            color: "#fff",
                                                                            textDecoration: "none",
                                                                        }}
                                                                    >
                                                                        Delist
                                                                    </a>
                                                                </ListItem>
                                                            ))
                                                        )}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {selectedPanel === "DNS Reputation" && (
                                        <Box>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    sx={{
                                                        padding: "20px",
                                                        border: "1px solid #f1f1f1",
                                                        borderRadius: "5px",
                                                        backgroundColor: "#fff",
                                                        overflowY: "auto",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#f9f9f9",
                                                            padding: "15px",
                                                            borderRadius: "8px",
                                                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                                            marginBottom: "20px",
                                                        }}
                                                    >
                                                        {Object.keys(dnsData).map((data, key) => {
                                                            return (
                                                                <p
                                                                    key={key}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        padding: "8px 0",
                                                                        borderBottom: "1px solid #e0e0e0",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            color: "#333",
                                                                        }}
                                                                    >
                                                                        {data.toUpperCase()}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                dnsData[data] === true
                                                                                    ? "#4CAF50"
                                                                                    : "#F44336", // Green for Pass, Red for Fail
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        {dnsData[data] ? "PASS" : "FAIL"}
                                                                    </span>
                                                                </p>
                                                            );
                                                        })}
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Box>
                                    )}
                                    {selectedPanel === "Overview" && <DNSOverviewChart inboxSpamCount={overview} />}
                                    {selectedPanel === "TemplateList" && (
                                        <TemplatePanel templateContent={templateContent} spamCheck={spamCheck} />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </>
            <SnackBar
                open={snackbarOpen}
                message={snackbarMessage}
                type="success"
                onClose={() => setSnackbarOpen(false)}
            />
        </Box>
    );
};
export default Report;
