import { useEffect, useState, useContext } from "react";
import AuditDialog from "./AuditDialog";
import EmailOnboardDialog from "./EmailOnboardDialog";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
const TestAudit = ({ onClose, onboardState, handleAuditSubmit }) => {
    const [openAudit, setOpenAudit] = useState(false);
    const [openOnBoard, setOpenOnBoard] = useState(false);
    const [senderList, setSenderList] = useState([]);
    const workctx = useContext(WorkspaceContext);
    useEffect(() => {
        fetchSenderList();
    }, []);

    const fetchSenderList = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchSenderList?workSpaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSenderList(data.data);
                    setOpenAudit(true);
                } else {
                    setOpenOnBoard(true);
                }
            });
    };

    const handleEmailSuccess = (data) => {
        setOpenAudit(false);
        setOpenOnBoard(false);
        handleAuditSubmit(data);
    };
    const handleOnClose = () => {
        setOpenAudit(false);
        setOpenOnBoard(false);
        onClose();
    };

    const handleImapClose = () => {
        setOpenAudit(false);
        fetchSenderList();
    };
    return (
        <>
            <AuditDialog
                open={openAudit}
                onClose={handleOnClose}
                senderList={senderList}
                onSubmit={(data) => handleEmailSuccess(data)}
            />
            <EmailOnboardDialog
                open={openOnBoard}
                onClose={handleOnClose}
                handleImapClose={handleImapClose}
                onboardState={onboardState}
                selectedButton="email-audit"
            />
        </>
    );
};
export default TestAudit;
