import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
const CustomBreadCrumbs = ({ actions }) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" separator="››" className="bread-crumbs" sx={{ marginBottom: "10px" }}>
            {actions.map((action, index) => (
                <Box key={index}>
                    {!(index === actions.length - 1) ? (
                        <Link
                            underline="hover"
                            onClick={action.onClick}
                            sx={{
                                color: "primary.main",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            {action.title}
                        </Link>
                    ) : (
                        <Typography
                            color="text.primary"
                            sx={{
                                color: "text.primary",
                            }}
                        >
                            {action.title}
                        </Typography>
                    )}
                </Box>
            ))}
        </Breadcrumbs>
    );
};
export default CustomBreadCrumbs;
