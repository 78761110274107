import { useRef } from "react";
import { Typography, Box, Grid, Card, CardActionArea, CardContent } from "@mui/material";
import inbox from "../../img/images/inbox.png";
import spam from "../../img/images/spam.png";
import Google from "../../img/images/google.png";
import BarChartIcon from "@mui/icons-material/BarChart";
import Outlook from "../../img/images/outlook.png";
const ESP = ({ iptCounts }) => {
    const contentRef = useRef(null);
    const totalSpamCount = parseInt(iptCounts?.totalSpamCount, 10) || 0;
    const totalInboxCount = parseInt(iptCounts?.totalInboxCount, 10) || 0;
    return (
        <Grid item xs={3} sm={8} md={8}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item md={6}>
                    <Card
                        style={{
                            "&:hover": { backgroundColor: "#F7F7F9" },
                            borderRadius: "8px",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            mb: 2,
                        }}
                    >
                        <CardActionArea>
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    textAlign="center"
                                    sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                                >
                                    <img
                                        src={inbox}
                                        alt=""
                                        style={{
                                            height: "31px",
                                            width: "32px",
                                        }}
                                    />
                                    {"Inbox Count"}
                                </Typography>
                                <Typography variant="h5" color="primary" textAlign="center">
                                    {totalInboxCount}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card
                        style={{
                            "&:hover": { backgroundColor: "#F7F7F9" },
                            borderRadius: "8px",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            mb: 2,
                        }}
                    >
                        <CardActionArea>
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    textAlign="center"
                                    sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                                >
                                    <img
                                        src={spam}
                                        alt=""
                                        style={{
                                            height: "31px",
                                            width: "32px",
                                        }}
                                    />
                                    {"Spam Count"}
                                </Typography>
                                <Typography variant="h5" color="error" textAlign="center">
                                    {totalSpamCount}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Box
                ref={contentRef}
                style={{
                    maxHeight: 180,
                    overflowY: "auto",
                    padding: 2,
                    "&::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "8px",
                    },
                    msOverflowStyle: "auto", // IE and Edge
                    scrollbarWidth: "auto", // Firefox
                    marginTop: "10px",
                }}
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={6}>
                        <Card
                            sx={{
                                "&:hover": { backgroundColor: "#F7F7F9" },
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                mb: 2,
                            }}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        textAlign="center"
                                        sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                                    >
                                        <img
                                            src={Google}
                                            alt=""
                                            style={{
                                                height: "31px",
                                                width: "32px",
                                            }}
                                        />
                                        ESP Count
                                    </Typography>
                                    <Typography variant="h5" textAlign="center">
                                        <span style={{ color: "#536dfe" }}>{iptCounts?.googleInboxCount || 0}</span>/
                                        <span style={{ color: "#e91e63" }}>{iptCounts?.googleSpamCount || 0}</span>
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <Box
                                                sx={{
                                                    bgcolor: "#536dfe",
                                                    width: 10,
                                                    height: 10,
                                                }}
                                            />
                                            <span>Inbox</span>
                                        </div>
                                        <div>
                                            <Box
                                                sx={{
                                                    bgcolor: "#e91e63",
                                                    width: 10,
                                                    height: 10,
                                                }}
                                            />
                                            Spam
                                        </div>
                                    </Box>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item md={6}>
                        <Card
                            sx={{
                                "&:hover": { backgroundColor: "#F7F7F9" },
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                mb: 2,
                            }}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        textAlign="center"
                                        sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                                    >
                                        <img
                                            src={Outlook}
                                            alt=""
                                            style={{
                                                height: "31px",
                                                width: "32px",
                                            }}
                                        />
                                        ESP Count
                                    </Typography>
                                    <Typography variant="h5" textAlign="center">
                                        <span style={{ color: "#536dfe" }}>{iptCounts?.outlookInboxCount || 0}</span>/
                                        <span style={{ color: "#e91e63" }}>{iptCounts?.outlookSpamCount || 0}</span>
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <Box
                                                sx={{
                                                    bgcolor: "#536dfe",
                                                    width: 10,
                                                    height: 10,
                                                }}
                                            />
                                            <span>Inbox</span>
                                        </div>
                                        <div>
                                            <Box
                                                sx={{
                                                    bgcolor: "#e91e63",
                                                    width: 10,
                                                    height: 10,
                                                }}
                                            />
                                            Spam
                                        </div>
                                    </Box>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item md={12}>
                        <Card
                            sx={{
                                "&:hover": { backgroundColor: "#F7F7F9" },
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                mb: 2,
                            }}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        textAlign="center"
                                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    >
                                        <BarChartIcon sx={{ fontSize: 40, color: "#333", mr: 1 }} />
                                        ESP Count
                                    </Typography>
                                    <Typography variant="h5" textAlign="center">
                                        <span style={{ color: "#536dfe" }}>{iptCounts?.otherInboxCount || 0}</span>/
                                        <span style={{ color: "#e91e63" }}>{iptCounts?.otherSpamCount || 0}</span>
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <Box
                                                sx={{
                                                    bgcolor: "#536dfe",
                                                    width: 10,
                                                    height: 10,
                                                }}
                                            />
                                            <span>Inbox</span>
                                        </div>
                                        <div>
                                            <Box
                                                sx={{
                                                    bgcolor: "#e91e63",
                                                    width: 10,
                                                    height: 10,
                                                }}
                                            />
                                            Spam
                                        </div>
                                    </Box>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};
export default ESP;
