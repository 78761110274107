import { useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailOnboardDialog from "../../../EmailOnBoard/Onboard";
const AddSender = ({ handleBack, handleImapClose }) => {
    const [openOnBoard, setOpenOnBoard] = useState(false);
    const handleAddNewSender = () => {
        setOpenOnBoard(true);
    };
    return (
        <Box>
            {openOnBoard ? (
                <EmailOnboardDialog
                    onClose={handleBack}
                    selectedButton="email-audit-v1?tab=automatic,auditTest=true"
                    handleImapClose={handleImapClose}
                />
            ) : (
                <Box
                    sx={{
                        maxWidth: 800,
                        margin: "auto",
                        padding: "20px",
                        textAlign: "center",
                    }}
                >
                    <IconButton disableRipple sx={{ fontSize: 80, color: "black" }}>
                        <MailOutlineIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                        No Sending account here yet...
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                        To create an Automatic Test, you should Add your sending account first.
                    </Typography>

                    <Button
                        variant="contained"
                        color="info"
                        sx={{ mt: 3, textTransform: "none", px: 4 }}
                        onClick={handleAddNewSender}
                    >
                        Add a Sender Email
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default AddSender;
