import React, { useContext, useState } from "react";
import { Box, Typography, Button, Chip } from "@mui/material";
import Upgrade from "../Setting/Pricing/Upgrade";
import { AuthContext } from "../Provider/AuthProvider";

const FreeTrial = () => {
    const [showUpgrade, setShowUpgrade] = useState(false);
    const authCtx = useContext(AuthContext);

    const handleCloseUpgrade = () => {
        setShowUpgrade(false);
    };

    // Extract and handle plan details
    const planName = authCtx.PlanDetails?.planName || "";
    const cleanedPlanName = planName
        ?.replace(/\(Year\)/i, " (Annually)")
        .replace(/\(Month\)/i, " (Monthly)")
        .trim();

    // Hide the box if the plan contains "Advance"
    if (planName.toLowerCase().includes("advance")) {
        return null;
    }

    // Handle date difference calculation safely
    const endDate = authCtx.PlanDetails?.end_date ? new Date(authCtx.PlanDetails.end_date) : null;
    const currentDate = new Date();
    const timeDifference = endDate ? endDate - currentDate : 0;
    const daysDifference = Math.max(Math.floor(timeDifference / (1000 * 60 * 60 * 24)), 0);

    return (
        <Box
            sx={{
                width: 200,
                padding: 2,
                backgroundColor: "#f5f5f5",
                mb: 2,
            }}
        >
            <Typography variant="h6" sx={{ mb: 1, textWrap: "wrap" }}>
                {cleanedPlanName}
            </Typography>

            {/* Show remaining days or "Expired" */}
            <Chip
                label={daysDifference > 0 ? `${daysDifference} days left` : "Expired"}
                variant="outlined"
                sx={{
                    mb: 2,
                    borderRadius: "16px",
                    backgroundColor: "#fff",
                }}
            />

            {/* Upgrade Button */}
            <Button
                variant="contained"
                color="success"
                onClick={() => setShowUpgrade(true)}
                sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    display: "block",
                }}
            >
                Upgrade
            </Button>

            {/* Upgrade Modal */}
            {showUpgrade && <Upgrade open={showUpgrade} onClose={handleCloseUpgrade} />}
        </Box>
    );
};

export default FreeTrial;
