import { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import Paper from "@mui/material/Paper";
import { Typography, Pagination, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { format } from "date-fns";
import WorkspaceManagement from "./WokspaceManageMent";
import { Edit } from "@mui/icons-material";
import { SkeletonList } from "../common/SkeletonList";
import { useLocation } from "react-router-dom";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import { Button } from "rsuite";
import SnackBar from "../common/SnackBar";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
export default function WorkspaceList() {
    const workspaceCtx = useContext(WorkspaceContext);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [showWorkSpace, setShowWorkSpace] = useState(false);
    const [workSpaceData, setWorkSpaceData] = useState(null);
    const [openDialogWorkSpace, setOpenDialogWorkspace] = useState(false);
    const [newWorkspaceName, setNewWorkspaceName] = useState("");
    const [hideCreate, setHideCreate] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const workspacesPerPage = 10;
    const indexOfLastWorkspace = currentPage * workspacesPerPage;
    const indexOfFirstWorkspace = indexOfLastWorkspace - workspacesPerPage;
    const currentWorkspaces = workspaceCtx.workspaceList.slice(indexOfFirstWorkspace, indexOfLastWorkspace);
    const location = useLocation();
    const workspaceId = new URLSearchParams(location.search).get("id");
    const handleAddWorkspaceClick = (event) => {
        setOpenDialogWorkspace(true);
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    useEffect(() => {
        if (workspaceId && workspaceCtx.workspaceList.length > 0) {
            const data = workspaceCtx.workspaceList.find((workspace) => workspaceId === String(workspace.id));

            if (data) {
                setWorkSpaceData(data);
                setShowWorkSpace(true);
            } else {
                console.error("Workspace not found");
            }
        }
    }, [workspaceId, workspaceCtx.workspaceList]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "do MMM, HH:mm");
    };
    const handleWorkSpace = (workspace) => {
        console.log(workspace, "workspaces");
        setShowWorkSpace(true);
        setWorkSpaceData(workspace);
    };
    useEffect(() => {
        setTimeout(() => {
            setShowSkeleton(false);
        }, 4000);
    }, []);
    const handleSaveClick = () => {
        if (!newWorkspaceName && newWorkspaceName.trim().length < 5) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Invalid WorkSpace Name");
            setNewWorkspaceName("");
            return;
        }
        setHideCreate(true);
        fetch(`${REACT_APP_SERVER_URL}/createworkspace`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                name: newWorkspaceName,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(data.message);
                    workspaceCtx.handleUpdateWorkspace();
                } else {
                    setNewWorkspaceName("");
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                }
                setHideCreate(false);
                setOpenDialogWorkspace(false);
            });
    };
    return (
        <Box sx={{ flexGrow: 1, width: "100%", padding: 2 }}>
            {workspaceCtx.workspaceLimit.role === 2 && (
                <Button
                    color="primary"
                    style={{ float: "right", marginBottom: "10px" }}
                    onClick={handleAddWorkspaceClick}
                >
                    Add Workspace
                </Button>
            )}
            {!showWorkSpace ? (
                <Grid container spacing={2}>
                    {showSkeleton ? (
                        <SkeletonList />
                    ) : (
                        <Grid item xs={12}>
                            {workspaceCtx.workspaceList.length > 0 ? (
                                <>
                                    <List>
                                        {currentWorkspaces.map((workspace) => (
                                            <Paper
                                                key={workspace.id}
                                                elevation={2}
                                                sx={{
                                                    marginBottom: 2,
                                                    backgroundColor: "#ffffff",
                                                    width: "100%",
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <ListItem
                                                    sx={{
                                                        width: "100%",
                                                        "&:hover": {
                                                            backgroundColor: "#f0f0f0",
                                                            cursor: "pointer",
                                                            "& .edit-icon": {
                                                                // Targeting the Edit icon on hover
                                                                visibility: "visible",
                                                            },
                                                        },
                                                    }}
                                                    onClick={() => handleWorkSpace(workspace)}
                                                >
                                                    <ListItemIcon>
                                                        <FolderIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={workspace.name}
                                                        secondary={
                                                            <span>
                                                                <i>Created at : </i>
                                                                {formatDate(workspace.createdAt)}
                                                            </span>
                                                        }
                                                    />

                                                    <ListItemIcon
                                                        sx={{
                                                            visibility: "hidden",
                                                        }}
                                                        className="edit-icon"
                                                    >
                                                        <Edit />
                                                    </ListItemIcon>
                                                </ListItem>
                                            </Paper>
                                        ))}
                                    </List>
                                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                                        {workspaceCtx.workspaceList.length > 10 && (
                                            <Pagination
                                                count={Math.ceil(workspaceCtx.workspaceList.length / workspacesPerPage)}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                color="primary"
                                                variant="outlined"
                                            />
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <Typography>No Workspaces Found</Typography>
                            )}
                        </Grid>
                    )}
                </Grid>
            ) : (
                <WorkspaceManagement
                    workSpaceData={workSpaceData}
                    handleCloseWorkSpace={() => setShowWorkSpace(false)}
                />
            )}
            {openDialogWorkSpace && (
                <Dialog
                    open={openDialogWorkSpace}
                    onClose={() => {
                        setOpenDialogWorkspace(false);
                        setNewWorkspaceName("");
                    }}
                >
                    <DialogTitle sx={{ margin: "10px !important", textAlign: "center" }}>Create workspace</DialogTitle>
                    <DialogContent sx={{ paddingTop: "10px !important" }}>
                        <Box
                            component="form"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                width: "400px",
                            }}
                        >
                            <TextField
                                fullWidth
                                label="Workspace Name"
                                value={newWorkspaceName}
                                onChange={(e) => setNewWorkspaceName(e.target.value)}
                                variant="outlined"
                                error={newWorkspaceName && newWorkspaceName.trim().length < 5}
                                helperText={
                                    newWorkspaceName && newWorkspaceName.trim().length < 5
                                        ? "name must be at least 5 characters long"
                                        : ""
                                }
                            />
                            <Box display="flex" marginTop={2}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setOpenDialogWorkspace(false);
                                        setNewWorkspaceName("");
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!newWorkspaceName || newWorkspaceName.trim().length < 5 || hideCreate}
                                    onClick={handleSaveClick}
                                    sx={{ marginLeft: "10px" }}
                                >
                                    Create
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </Box>
    );
}
