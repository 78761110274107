import React from "react";
import { Box, Typography } from "@mui/material";
import BarChart from "../../img/images/barChart.png";
import Google from "../../img/images/google.png";
import Outlook from "../../img/images/outlook.png";

const ESPDashboard = ({ totalCount }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-around",
                padding: "16px",
            }}
        >
            <Box sx={{ display: "flex", gap: 6 }}>
                <Box
                    sx={{
                        bgcolor: "success.main",
                        borderRadius: "50%",
                        width: 150,
                        height: 150,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        fontWeight: "bold",
                    }}
                >
                    Inbox
                    <Typography variant="h6">{totalCount?.totalInboxCount || "--"}</Typography>
                </Box>
                <Box
                    sx={{
                        bgcolor: "error.main",
                        borderRadius: "50%",
                        width: 150,
                        height: 150,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        fontWeight: "bold",
                    }}
                >
                    Spam
                    <Typography variant="h6">{totalCount?.totalSpamCount || "--"}</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    display: "flex",
                    width: "20rem",
                    flexDirection: "column",
                    gap: 1,
                }}
            >
                <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>ESP Count</Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: 4,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box>
                        <Box>
                            <img
                                src={Google}
                                alt=""
                                style={{
                                    height: "50px",
                                    width: "50px",
                                }}
                            />
                        </Box>
                        <Typography sx={{ fontSize: "23px", textAlign: "center" }}>
                            <Typography component="span" color="primary" fontWeight="bold">
                                {totalCount ? totalCount.googleInboxCount : "--"}
                            </Typography>
                            /
                            <Typography component="span" color="error" fontWeight="bold">
                                {totalCount ? totalCount.googleSpamCount : "--"}
                            </Typography>
                        </Typography>
                    </Box>
                    <Box>
                        <Box>
                            <img
                                src={Outlook}
                                alt=""
                                style={{
                                    height: "50px",
                                    width: "50px",
                                }}
                            />
                        </Box>
                        <Typography sx={{ fontSize: "23px", textAlign: "center" }}>
                            <Typography component="span" color="primary" fontWeight="bold">
                                {totalCount ? totalCount.outlookInboxCount : "--"}
                            </Typography>
                            /
                            <Typography component="span" color="error" fontWeight="bold">
                                {totalCount ? totalCount.outlookSpamCount : "--"}
                            </Typography>
                        </Typography>
                    </Box>
                    <Box>
                        <Box>
                            <img
                                src={BarChart}
                                alt=""
                                style={{
                                    height: "50px",
                                    width: "50px",
                                }}
                            />
                        </Box>
                        <Typography sx={{ fontSize: "23px", textAlign: "center" }}>
                            <Typography component="span" color="primary" fontWeight="bold">
                                {totalCount ? totalCount.otherInboxCount : "--"}
                            </Typography>
                            /
                            <Typography component="span" color="error" fontWeight="bold">
                                {totalCount ? totalCount.otherSpamCount : "--"}
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ESPDashboard;
