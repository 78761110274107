import React, { useContext, useState } from "react";
import { TextField, Button, Typography, Box, Dialog, DialogTitle, CircularProgress } from "@mui/material";
import SnackBar from "../common/SnackBar";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import { AuthContext } from "../Provider/AuthProvider";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Step1 = ({ onNext, onClose }) => {
    const workCtx = useContext(WorkspaceContext);
    const authCtx = useContext(AuthContext);
    const [domain, setDomain] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const domainRegex = /^(?!-)(?!.*--)[A-Za-z0-9-]+(\.[A-Za-z]{2,})+$/;
    const isInvalidDomain = domain && !domainRegex.test(domain);
    const addDomain = () => {
        if (!domain) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Please enter the domain.");
            return;
        }
        const domainRegex = /^(?!-)(?!.*--)[A-Za-z0-9-]+(\.[A-Za-z]{2,})+$/;

        if (domain && !domainRegex.test(domain)) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Enter a valid domain.");
            return;
        }
        setButtonLoading(true);
        console.log(domain, "domain is getting");
        fetch(`${REACT_APP_SERVER_URL}/addDomain`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                domain: domain,
                workSpaceId: workCtx.activeWorkspace,
                userEmail: authCtx.user.email,
                businessName: businessName,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    onNext(data.domain, data.txtRecord);
                } else {
                    setButtonLoading(false);
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                }
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addDomain();
    };
    const handleBlur = () => {
        if (!businessName && !isInvalidDomain) {
            const extractedValue = domain.includes(".") ? domain.split(".")[0] : domain; // Use the entire value if no dot is present
            setBusinessName(extractedValue.trim());
        }
    };

    return (
        <Box>
            <Dialog open onClose={onClose} style={{ width: "100%", borderRadius: "20px" }}>
                <Box>
                    <DialogTitle style={{ borderBottom: "1px solid #f1f1f1", padding: "10px" }}>
                        <Box sx={{ margin: "20px" }}>
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    lineHeight: "28px",
                                    textAlign: "center",
                                }}
                            >
                                {" "}
                                Add a Domain
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <Box sx={{ marginTop: "0px", padding: "8px 15px", borderBottom: "1px solid #f1f1f1" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <TextField
                                fullWidth
                                label="Enter the domain"
                                variant="outlined"
                                placeholder="yourdomain.com"
                                value={domain}
                                error={isInvalidDomain} // Show error if passwords do not match
                                helperText={isInvalidDomain ? "Invalid Domain" : ""}
                                size="small"
                                onChange={(e) => setDomain(e.target.value)}
                                style={{ marginBottom: "1rem", borderRadius: "4px", margin: "5px" }}
                                InputLabelProps={{
                                    shrink: true, // Ensures the label stays at the top
                                }}
                                onBlur={handleBlur}
                            />
                            <TextField
                                fullWidth
                                label="Business Name"
                                variant="outlined"
                                placeholder="Business Name"
                                value={businessName}
                                size="small"
                                onChange={(e) => setBusinessName(e.target.value)}
                                style={{ marginBottom: "1rem", borderradius: "4px", margin: "5px" }}
                                InputLabelProps={{
                                    shrink: true, // Ensures the label stays at the top
                                }}
                                onBlur={handleBlur}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                justifyItems: "center",
                                alignItems: "center",
                                alignContent: "center",
                                marginTop: "10px",
                                padding: "8px 0px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "21px",
                                    marginTop: "10x",
                                    color: "rgba(71, 84, 103, 1)",
                                }}
                                variant="body1"
                            >
                                Add the domain you would like to send emails from. You must have access to this domain's
                                DNS records in order to verify it
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ marginLeft: "60%", display: "flex", marginTop: "15px" }}>
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{
                                width: "100px",
                                height: "35px",
                                fontsize: "14px",
                                cursor: "pointer",
                                margin: "10px",
                            }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ width: "200px", height: "35px", fontsize: "16px", cursor: "pointer", margin: "10px" }}
                            disabled={buttonLoading}
                            onClick={handleSubmit}
                        >
                            {buttonLoading ? <CircularProgress size={24} /> : "Add"}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};

export default Step1;
