import React, { useContext, useState, useEffect } from "react";
import {
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    Menu,
    Paper,
    Skeleton,
    Pagination,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DateRangeIcon from "@mui/icons-material/DateRange";
import dayjs from "dayjs";
import { tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "1rem",
        fontWeight: "bold",

        padding: "12px", // Add space in cells
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18px",

        padding: "12px", // Add space in cells
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:hover": {
        backgroundColor: "#f1f1f1 !important",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function CampaignReports() {
    const [statusFilter, setStatusFilter] = useState("");
    const [toolFilter, setToolFilter] = useState("");
    const [searchText, setSearchText] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [actionMenu, setActionMenu] = useState(null);
    const [openAction, setOpenAction] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [campaignData, setCampaignData] = useState([]);
    const [totalRecords, setTotalRecords] = useState("");

    const [page, setPage] = useState(1);
    const workctx = useContext(WorkspaceContext);
    const rowsPerPage = 10;
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const handleActionMenu = (event, row) => {
        setActionMenu(event.currentTarget);
        setOpenAction(true);
        setSelectedRow(row);
    };
    const handleActionClose = () => {
        setActionMenu(null);
        setOpenAction(false);
        setSelectedRow(null);
    };
    const transformCampaignData = (data) => {
        const getValue = (obj, key) => (key in obj && obj[key] !== null ? obj[key] : "");

        return data
            .map((campaign) => ({
                campaign: getValue(campaign, "name"),
                status: getValue(campaign, "status"),
                emailsSent: parseInt(getValue(campaign, "sent_count"), 10) || 0,
                spam: parseInt(getValue(campaign, "spam_count"), 10) || 0,
                open: parseInt(getValue(campaign, "open_count"), 10) || 0,
                click: parseInt(getValue(campaign, "click_count"), 10) || 0,
                tool: getValue(campaign, "tools"),
                createdAt: getValue(campaign, "created_at"),
                createdOn: getValue(campaign, "created_at") ? new Date(campaign.created_at) : null,
            }))
            .sort((a, b) => {
                if (a.createdOn && b.createdOn) {
                    return b.createdOn - a.createdOn;
                }
                return a.createdOn ? -1 : 1;
            });
    };

    const fetchCampaignData = () => {
        setShowSkeleton(true);
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/campaignDataFetch?workSpaceId=${workctx.activeWorkspace}&page=${page}&limit=${rowsPerPage}`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    const simplifiedData = transformCampaignData(data.data);
                    setCampaignData(simplifiedData); // Set transformed data to state
                    setTotalRecords(data.totalRecords);
                    console.log(simplifiedData, "simplifiedData");
                }
                console.log(data, "here campaignData");
            })
            .catch((error) => {
                console.error("Fetch error: ", error);
            })
            .finally(() => setShowSkeleton(false));
    };
    const filteredCampaigns = campaignData.filter((campaign) => {
        const matchesSearch = searchText ? campaign.campaign.toLowerCase().includes(searchText.toLowerCase()) : true;

        const matchesStatus = statusFilter ? campaign.status === statusFilter : true;

        const matchesTool = toolFilter ? campaign.tool === toolFilter : true;

        const matchesDate = selectedDate ? dayjs(campaign.createdOn).isSame(selectedDate, "day") : true;

        return matchesSearch && matchesStatus && matchesTool && matchesDate;
    });
    useEffect(() => {
        fetchCampaignData();
    }, [page]);
    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, textAlign: "left" }}>
                View and manage reports for your audited campaigns.
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 3,
                    flexWrap: "wrap",
                }}
            >
                <TextField
                    placeholder="Search by campaign"
                    size="small"
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{ flexGrow: 1 }}
                />

                <Select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    displayEmpty
                    size="small"
                    sx={{ minWidth: 150 }}
                >
                    <MenuItem value="">All Status</MenuItem>
                    <MenuItem value="COMPLETED">Completed</MenuItem>
                    <MenuItem value="PAUSED">Paused</MenuItem>
                    <MenuItem value="DRAFTED">Drafted</MenuItem>
                </Select>

                <Select
                    value={toolFilter}
                    onChange={(e) => setToolFilter(e.target.value)}
                    displayEmpty
                    size="small"
                    sx={{ minWidth: 150 }}
                >
                    <MenuItem value="">All Tools</MenuItem>
                    <MenuItem value="smartlead">Smartlead</MenuItem>
                    <MenuItem value="instantly">Instantly</MenuItem>
                </Select>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {showDatePicker ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={selectedDate}
                                onChange={(newDate) => setSelectedDate(newDate)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        placeholder="Select Date"
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton>
                                                    <DateRangeIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    ) : (
                        <IconButton onClick={() => setShowDatePicker(true)}>
                            <DateRangeIcon />
                        </IconButton>
                    )}
                    {(selectedDate || showDatePicker) && (
                        <IconButton
                            onClick={() => {
                                setSelectedDate(null);
                                setShowDatePicker(false);
                            }}
                            color="primary"
                            title="Clear Date"
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Campaign</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Emails Sent</StyledTableCell>
                            <StyledTableCell>Spam</StyledTableCell>
                            <StyledTableCell>Open</StyledTableCell>
                            <StyledTableCell>Click</StyledTableCell>
                            <StyledTableCell>Tool</StyledTableCell>
                            <StyledTableCell>Created On</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {showSkeleton ? (
                        <TableBody>
                            {[...Array(5)].map((_, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton variant="rectangular" width={30} height={30} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <>
                            {filteredCampaigns.length > 0 ? (
                                <TableBody>
                                    {filteredCampaigns
                                        // .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                        .map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>{row.campaign}</StyledTableCell>
                                                <StyledTableCell>
                                                    <Typography
                                                        sx={{
                                                            color:
                                                                row.status === "COMPLETED"
                                                                    ? "green"
                                                                    : row.status === "PAUSED"
                                                                      ? "orange"
                                                                      : "blue",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {row.status.toLowerCase()}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell>{row.emailsSent}</StyledTableCell>
                                                <StyledTableCell>{row.spam}</StyledTableCell>
                                                <StyledTableCell>{row.open}</StyledTableCell>
                                                <StyledTableCell>{row.click}</StyledTableCell>
                                                <StyledTableCell>{row.tool}</StyledTableCell>
                                                <StyledTableCell>
                                                    {dayjs(row.createdOn).format("DD-MMM-YYYY")}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <MoreVertIcon
                                                        id="basic-button"
                                                        aria-controls={
                                                            openAction && selectedRow?.campaign === row.campaign
                                                                ? "basic-menu"
                                                                : undefined
                                                        }
                                                        aria-haspopup="true"
                                                        aria-expanded={
                                                            openAction && selectedRow?.campaign === row.campaign
                                                                ? "true"
                                                                : undefined
                                                        }
                                                        onClick={(event) => handleActionMenu(event, row)}
                                                        sx={{ cursor: "pointer", fontWeight: "bold" }}
                                                    />

                                                    {selectedRow?.campaign === row.campaign && (
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={actionMenu}
                                                            open={openAction}
                                                            onClose={handleActionClose}
                                                            MenuListProps={{
                                                                "aria-labelledby": "basic-button",
                                                            }}
                                                        >
                                                            <MenuItem onClick={handleActionClose}>View Audit</MenuItem>
                                                            <MenuItem onClick={handleActionClose}>Delete</MenuItem>
                                                            {row.status === "DRAFTED" && (
                                                                <MenuItem onClick={handleActionClose}>
                                                                    Start Campaign
                                                                </MenuItem>
                                                            )}
                                                        </Menu>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={9} align="center">
                                            No Data Found
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )}
                        </>
                    )}
                </Table>
            </TableContainer>
            {filteredCampaigns.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <Pagination
                        count={Math.ceil(totalRecords / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                        variant="outlined"
                    />
                </Box>
            )}
        </Box>
    );
}
