import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Box, Typography, TextField, Select, MenuItem, IconButton, InputAdornment } from "@mui/material";
import CustomBreadCrumbs from "../common/CustomBreadCrumbs";
import EmailTemplate from "./EmailTemplate";
import TableComponent from "../common/TableComponent";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import { CircularProgress } from "@material-ui/core";
import { SocketContext } from "../Provider/SocketProvider";
const EmailLogs = ({ type, handleBack, selectedEmailRow, recipient, handleHideEsp }) => {
    console.log(selectedEmailRow, "selected Email Row");
    let count = useRef(0);
    const socket = useContext(SocketContext);
    const workctx = useContext(WorkspaceContext);
    const [List, setList] = useState([]);
    const [dataFetched, setDataFetched] = useState(recipient ? false : true);
    const [showEmailTemplate, setShowEmailTemplate] = useState(false);
    const [recipientDetails, setRecipientDetails] = useState({});
    const [toolFilter, setToolFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [searchText, setSearchText] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [loader, setLoader] = useState(false);
    const [regenerate, setRegenerate] = useState(false);
    const [key, setKey] = useState(0);
    const columns = [
        { field: "recipient", headerName: "Recipient", sortable: true },
        { field: "subject", headerName: "Subject", sortable: true },
        { field: "status", headerName: "Delivery Status", sortable: true },
        { field: "esp", headerName: "ESP", sortable: true },
        { field: "created", headerName: "Created", sortable: true },
        { field: "action", headerName: "Actions", sortable: false },
    ];
    const Actions = [{ title: "View Report", click: (row) => handleShowEmailTemplate(row) }];

    const fetchSenderEmailLogs = () => {
        setLoader(true);
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/fetchSenderLevelEmailLogs?sender=${selectedEmailRow.email || ""}&workspaceId=${workctx.activeWorkspace}`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setList(data.data);
                }
                console.log(data, "emailogs");
            })
            .catch((err) => console.log(err))
            .finally(() => setTimeout(() => setLoader(false), 2000));
    };
    const fetchAllEmailLogs = () => {
        setLoader(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchEmailLogsByWorkspace?workspaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setList(data.data);
                }
                console.log(data, "emailogs");
            })
            .catch((err) => console.log(err))
            .finally(() => setTimeout(() => setLoader(false), 2000));
    };

    const filteredRecipients = useMemo(() => {
        const transformedRows = List.map((row) => ({
            recipient: row.to,
            subject: `Subject for ${row.subject}`,
            status: row.label === "Inbox" ? "Inbox" : "Spam",
            esp: row.esp === "googleEsp" ? "Google" : row.esp === "outlookEsp" ? "Outlook" : "Other",
            created: new Date(row.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-"), // Converts to DD-MM-YYYY format
        }));
        return transformedRows.filter((emailList) => {
            console.log(emailList, "emailList");
            const matchesSearch = searchText
                ? emailList.recipient.toLowerCase().includes(searchText.toLowerCase())
                : true;

            const matchesStatus = statusFilter ? emailList.status === statusFilter : true;

            const matchesESP = toolFilter ? emailList.esp === toolFilter : true;
            const matchesDate = selectedDate
                ? dayjs(selectedDate).format("DD-MM-YYYY") ===
                  dayjs(emailList.created, "DD-MM-YYYY").format("DD-MM-YYYY")
                : true;

            return matchesSearch && matchesStatus && matchesESP && matchesDate;
        });
    }, [List, searchText, statusFilter, toolFilter, selectedDate]);
    useEffect(() => {
        console.log(filteredRecipients, count.current, "i am filtereds sender");
        if (filteredRecipients.length > 0) {
            setLoader(false);
        } else {
            count.current = count.current + 1;
            if (count.current >= 3) {
                setLoader(false);
            }
        }
    }, [filteredRecipients]);
    useEffect(() => {
        if (type === "all") {
            fetchAllEmailLogs();
        } else {
            fetchSenderEmailLogs();
        }
    }, []);
    const handleShowEmailTemplate = async (row) => {
        handleHideEsp();
        setShowEmailTemplate(true);
        const find = List.find((item) => item.to === row.recipient);
        setRecipientDetails(find);
    };
    const hideEmailTemplate = () => {
        handleHideEsp();
        setRegenerate(false);
        setShowEmailTemplate(false);
        setRecipientDetails(null);
    };
    console.log(showEmailTemplate, "emailtemplate");
    useEffect(() => {
        if (recipient) {
            console.log("underEmailTemplate", List, recipient);
            const find = List.find((item) => item.to === recipient);
            console.log(find, "findDetailsofRecepientssss");
            if (find !== undefined) {
                setRecipientDetails(find);
                handleHideEsp();
                setShowEmailTemplate(true);
                setDataFetched(true);
            }
        }
    }, [recipient, List]);
    useEffect(() => {
        const handleNewMail = (data) => {
            console.log(data, "under socket");
            if (data?.data) {
                const newMail = data.data;
                if (parseInt(newMail.workSpaceId, 10) === workctx.activeWorkspace) {
                    if (type === "all" || newMail.sender === selectedEmailRow?.email) {
                        setList((prev) => [newMail, ...prev]);
                    }
                }
            }
        };

        socket.on("new-mail", handleNewMail);

        console.log("I am under socket useEffect");
        return () => {
            socket.off("new-mail", handleNewMail);
        };
    }, [socket, workctx.activeWorkspace, type, selectedEmailRow]);

    const handleRegenerate = () => {
        setRegenerate(true);
        setKey((prev) => prev + 1);
    };
    if (!dataFetched) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress /> {/* Show loader until data is fetched */}
            </Box>
        );
    }
    return (
        <Box key={key}>
            {showEmailTemplate ? (
                <EmailTemplate
                    regenerate={regenerate}
                    handleRegenerate={handleRegenerate}
                    recipientDetails={recipientDetails}
                    hideEmailTemplate={hideEmailTemplate}
                    createTemplate={recipient ? true : false}
                    actions={
                        type === "row"
                            ? [
                                  { title: "Sender Emails", onClick: handleBack },
                                  { title: "Activities", onClick: hideEmailTemplate },
                                  { title: "Report", onClick: null },
                              ]
                            : [
                                  { title: "Sender Emails", onClick: handleBack },
                                  { title: "Email Logs", onClick: hideEmailTemplate },
                                  { title: "Report", onClick: null },
                              ]
                    }
                    sender={selectedEmailRow}
                />
            ) : (
                <Box>
                    {type === "row" && (
                        <Box>
                            <CustomBreadCrumbs
                                actions={[
                                    { title: "Sender Emails", onClick: handleBack },
                                    { title: "Activities", onClick: null },
                                ]}
                            />
                        </Box>
                    )}
                    <Typography variant="h6" gutterBottom>
                        Recipient Email Logs
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        View the activity logs of emails sent to recipients. Monitor inbox and spam placement.
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mb: 3,
                            flexWrap: "wrap",
                        }}
                    >
                        <TextField
                            placeholder="Search by Recipients"
                            size="small"
                            variant="outlined"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            sx={{ flexGrow: 1 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            displayEmpty
                            size="small"
                            sx={{ minWidth: 150 }}
                        >
                            <MenuItem value="">All Status</MenuItem>
                            <MenuItem value="Inbox">Inbox</MenuItem>
                            <MenuItem value="Spam">Spam</MenuItem>
                        </Select>

                        <Select
                            value={toolFilter}
                            onChange={(e) => setToolFilter(e.target.value)}
                            displayEmpty
                            size="small"
                            sx={{ minWidth: 150 }}
                        >
                            <MenuItem value="">All ESP</MenuItem>
                            <MenuItem value="Google">Google</MenuItem>
                            <MenuItem value="Outlook">Outlook</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>

                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            {showDatePicker ? (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={selectedDate}
                                        onChange={(newDate) => setSelectedDate(newDate)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                placeholder="Select Date"
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton>
                                                            <DateRangeIcon />
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            ) : (
                                <IconButton onClick={() => setShowDatePicker(true)}>
                                    <DateRangeIcon />
                                </IconButton>
                            )}
                            {(selectedDate || showDatePicker) && (
                                <IconButton
                                    onClick={() => {
                                        setSelectedDate(null);
                                        setShowDatePicker(false);
                                    }}
                                    color="primary"
                                    title="Clear Date"
                                >
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                    <TableComponent
                        isLoading={loader}
                        rows={filteredRecipients}
                        columns={columns}
                        actions={Actions}
                        onClick={(row) => handleShowEmailTemplate(row)}
                        statusField="status"
                        statusColor={{
                            Spam: "error",
                            Inbox: "success",
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};
export default EmailLogs;
