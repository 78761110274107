import React, { useState, useEffect, useContext } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Skeleton,
    Box,
    Dialog,
    DialogContent,
} from "@mui/material";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import { tableCellClasses } from "@mui/material";
import EmailOnboardDialog from "../EmailOnBoard/Onboard";
import { styled } from "@mui/material/styles";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "1rem",
        fontWeight: "bold",

        padding: "12px", // Add space in cells
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18px",

        padding: "12px", // Add space in cells
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:hover": {
        backgroundColor: "#f1f1f1 !important",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const SenderEmailsPage = () => {
    const workctx = useContext(WorkspaceContext);
    const [emails, setEmails] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [showOnboard, setShowOnboard] = useState(false);
    useEffect(() => {
        fetchSenderList();
    }, []);

    const fetchSenderList = () => {
        setShowSkeleton(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchSenderList?workSpaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setEmails(data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setShowSkeleton(false));
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Button variant="contained" sx={{ mb: 2, float: "right" }} onClick={() => setShowOnboard(true)}>
                Add Email
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Email</StyledTableCell>
                            {/* <StyledTableCell>Actions</StyledTableCell> */}
                        </TableRow>
                    </TableHead>{" "}
                    {showSkeleton ? (
                        <TableBody>
                            {[...Array(5)].map((_, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                        <Skeleton variant="text" />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <>
                            {emails.length > 0 ? (
                                <TableBody>
                                    {emails.map((email) => (
                                        <StyledTableRow key={email.id}>
                                            <StyledTableCell>{email.mail}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={2} align="center">
                                            No Data Found
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )}
                        </>
                    )}
                </Table>
            </TableContainer>
            {showOnboard && (
                <Dialog
                    open={showOnboard}
                    onClose={() => {
                        setShowOnboard(false);
                    }}
                    maxWidth="xl"
                >
                    <DialogContent>
                        <EmailOnboardDialog
                            onClose={() => {
                                setShowOnboard(false);
                            }}
                            handleImapClose={() => {
                                setShowOnboard(false);
                                fetchSenderList();
                            }}
                            selectedButton="email-accounts"
                        />
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

export default SenderEmailsPage;
