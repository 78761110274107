import { Skeleton, List, ListItem, Paper, Grid, Box, Card, CardActionArea, CardContent } from "@mui/material";
const SkeletonLoader = () => {
    return (
        <Box className="container_box">
            <Grid container spacing={3}>
                {/* Score Card Section */}
                <Grid item xs={12} md={3}>
                    <Paper
                        style={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                            padding: "20px",
                            border: "1px solid #f1f1f1",
                            borderRadius: "5px",
                            backgroundColor: "#fff",
                            boxShadow: "none",
                        }}
                    >
                        <Box
                            style={{
                                width: "180px",
                                height: "180px",
                                position: "relative",
                            }}
                        >
                            <Skeleton variant="circular" width={180} height={180} />
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Grid container spacing={3}>
                        {Array.from({ length: 2 }).map((_, index) => (
                            <Grid item xs={12} sm={6} md={6} key={index}>
                                <Card
                                    sx={{
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                        mb: 2,
                                    }}
                                >
                                    <CardActionArea>
                                        <CardContent>
                                            {/* Simulating the icon */}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    mb: 2,
                                                }}
                                            >
                                                <Skeleton variant="circular" width={32} height={31} />
                                            </Box>

                                            <Skeleton variant="text" width="60%" height={28} sx={{ mx: "auto" }} />

                                            <Skeleton
                                                variant="text"
                                                width="40%"
                                                height={36}
                                                sx={{ mx: "auto", my: 2 }}
                                            />

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    mt: 2,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 1,
                                                    }}
                                                >
                                                    <Skeleton variant="rectangular" width={10} height={10} />
                                                    <Skeleton variant="text" width={50} height={20} />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 1,
                                                    }}
                                                >
                                                    <Skeleton variant="rectangular" width={10} height={10} />
                                                    <Skeleton variant="text" width={50} height={20} />
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            <Box style={{ marginTop: "10px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <List>
                            {[...Array(4)].map((_, index) => (
                                <ListItem key={index}>
                                    <Skeleton variant="text" width="100%" height={80} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2}>
                            {[...Array(2)].map((_, index) => (
                                <Grid item xs={12} md={6} key={index}>
                                    <Paper
                                        style={{
                                            padding: "20px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            height: "300px", // Set max height for scrollable section
                                            overflowY: "auto", // Enable vertical scrolling
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        <Skeleton variant="text" width={200} height={40} />

                                        <List>
                                            {[...Array(3)].map((_, index) => (
                                                <ListItem key={index}>
                                                    <Skeleton variant="text" width="100%" height={80} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
export default SkeletonLoader;
