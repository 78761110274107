import { useRef, useState } from "react";
import { Paper, Box, IconButton, ListItem, ListItemText, Typography, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SnackBar from "../common/SnackBar";
const TemplateList = ({ templates }) => {
    console.log(templates, "templatesssss");
    const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const handleCopy = (content) => {
        setSuccessSnackbarOpen(true);
        setSuccessMessage("Copied to clipboard");
        navigator.clipboard.writeText(content);
    };
    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft -= 150;
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += 150;
        }
    };
    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
    };

    return (
        <Paper
            sx={{
                border: "1px solid #f1f1f1",
                borderRadius: "5px",
                backgroundColor: "#fff",
                boxShadow: "none !important",
            }}
        >
            {templates.length > 1 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {templates.length > 5 && (
                        <IconButton onClick={scrollLeft} style={{ marginTop: "-25px" }}>
                            <ArrowBackIosIcon height={20} />
                        </IconButton>
                    )}
                    <Box
                        ref={scrollRef}
                        sx={{
                            display: "flex",
                            overflowX: "hidden",
                            width: "100%",
                        }}
                    >
                        {templates.map((template, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    className="template-card"
                                    onClick={() => handleTemplateSelect(template)}
                                    sx={{
                                        backgroundColor: "white",
                                        "&:hover": {
                                            backgroundColor: "#e0f2f1",
                                        },
                                        flex: "0 0 auto",
                                        width: "120px",
                                        margin: 2,
                                        padding: 2,
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        border: "1px solid #ccc",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <ListItemText
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography>
                                            {template.subject ? `${template.subject.split(" ")[0]}...` : "No Subject"}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            );
                        })}
                    </Box>
                    {templates.length > 5 && (
                        <IconButton onClick={scrollRight} style={{ marginTop: "-25px" }}>
                            <ArrowForwardIosIcon style={{ height: "20px" }} />
                        </IconButton>
                    )}
                </Box>
            )}
            <Grid container sx={{ padding: 2, margin: 2 }}>
                <Grid item xs={8}>
                    <Box
                        sx={{
                            display: "flex",
                            borderRadius: "5px",
                            padding: "10px",
                            marginBottom: "20px",
                            backgroundColor: "white",
                            alignItems: "center",
                            height: "40px",
                            gap: 2,
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: "bold",
                                color: "#24a0ed",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            Subject:
                        </Typography>
                        {selectedTemplate && (
                            <Typography
                                variant="body1"
                                sx={{
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    flexGrow: 1, // Allows the text to take up remaining space
                                }}
                            >
                                {selectedTemplate?.subject || "No Subject"}
                            </Typography>
                        )}
                        <IconButton onClick={() => handleCopy(selectedTemplate.subject)}>
                            {" "}
                            <ContentCopyIcon sx={{ marginLeft: "auto", cursor: "pointer", float: "right" }} />
                        </IconButton>
                    </Box>

                    {/* Body Display Box */}
                    <Box
                        sx={{
                            borderRadius: "5px",
                            padding: "10px",
                            backgroundColor: "white",
                            alignItems: "center",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                height: "300px",
                                overflowY: "auto",
                                padding: "10px",
                                backgroundColor: "#f9f9f9",
                                borderRadius: "5px",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: selectedTemplate?.body || "No Data",
                            }}
                        ></div>
                        <IconButton onClick={() => handleCopy(selectedTemplate?.body || "No Content")}>
                            {" "}
                            <ContentCopyIcon sx={{ marginLeft: "auto", cursor: "pointer", float: "right" }} />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={3}
                    sx={{
                        backgroundColor: "#F8DCD9",
                        padding: "10px",
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Spam Analysis
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            wordWrap: "break-word", // Ensures words wrap within the container
                            overflowWrap: "anywhere", // Allows breaking long strings
                        }}
                    >
                        {selectedTemplate?.modifiedContent || "No Data"}
                    </Typography>
                </Grid>
            </Grid>
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </Paper>
    );
};
export default TemplateList;
