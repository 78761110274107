import { useState } from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailAccountForm from "./EmailAccountForm";
const EmailAuditPage = ({ handleBackReport, rows }) => {
    const [showEmailAccountForm, setShowEmailAccountForm] = useState(false);

    const handleRunTestAudit = () => {
        setShowEmailAccountForm(true);
    };
    const handleBack = () => {
        setShowEmailAccountForm(false);
    };

    return (
        <>
            {!showEmailAccountForm ? (
                <Box
                    sx={{
                        maxWidth: 800,
                        margin: "auto",
                        padding: "20px",
                        textAlign: "center",
                    }}
                >
                    <IconButton disableRipple sx={{ fontSize: 80, color: "black" }}>
                        <MailOutlineIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                        Start Auditing Emails Sent from Any Email Account
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                        Click the button below to start auditing your emails by sending us a test email.
                    </Typography>

                    <Button
                        variant="contained"
                        color="info"
                        sx={{ mt: 3, textTransform: "none", px: 4 }}
                        onClick={handleRunTestAudit}
                    >
                        Run Test Audit
                    </Button>
                </Box>
            ) : (
                <EmailAccountForm
                    handleBack={handleBack}
                    handleBackReport={handleBackReport}
                    actions={[
                        { title: "Email Accounts", onClick: handleBack },
                        { title: "Campaign Form", onClick: null },
                    ]}
                    rows={rows}
                />
            )}
        </>
    );
};

export default EmailAuditPage;
