import React, { useContext, useState, useEffect } from "react";
import { styled, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import FreeTrial from "./FreeTrial";
import {
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
    List,
    ListItem,
    Drawer as MuiDrawer,
    Divider,
    Container,
    MenuItem,
    Menu,
    Stack,
    Button,
    ListItemText,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    ListItemIcon,
    CircularProgress,
    Skeleton,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useNavigate, Outlet, useLocation, Link } from "react-router-dom";
import emailaudit from "../../img/icons/email_audit.png";
import cookie from "cookiejs";
import { AuthContext } from "../Provider/AuthProvider";
import SnackBar from "../common/SnackBar";
import { Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SendIcon from "@mui/icons-material/Send";
import { PlanContext } from "../Provider/PlanContext";
const drawerWidth = 231;
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: "white",
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        height: "100vh",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const DashBoard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const existPath = location.pathname;
    const ctx = useContext(AuthContext);
    const planCtx = useContext(PlanContext);
    const [key, setKey] = useState(0);
    const workspaceContext = useContext(WorkspaceContext);
    const [newWorkspaceName, setNewWorkspaceName] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [fname, setFName] = useState("");
    const [open, setOpen] = useState(true);
    const [profileMenuOpen, setProfileMenuOpen] = useState(null);
    const [openDialogWorkSpace, setOpenDialogWorkspace] = useState(false);
    const [email, setEmail] = useState(false);
    const [activeWorkspace, setActiveWorkspace] = useState(null);
    const [activeWorkspaceName, setActiveWorkspaceName] = useState(null);
    const [hideCreate, setHideCreate] = useState(false);
    console.log(planCtx.features, planCtx.hideDashboard, "Dasboard");
    const avatarText = fname ? fname.substring(0, 2).toUpperCase() : "";
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        setActiveMenu(existPath);
    }, [existPath]);
    useEffect(() => {
        setKey((prevKey) => prevKey + 1);
    }, [activeWorkspace]);
    useEffect(() => {
        let isLogin = cookie.get("token");
        if (!isLogin) navigate("/login");
        else {
            setFName(ctx.user.firstname || ctx.user.first_name || ctx.user.first_name);
            setEmail(ctx.user.email);
        }
    }, [navigate, ctx]);
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(null);
    };
    const handleProfileMenuOpen = (event) => {
        setProfileMenuOpen(event.currentTarget);
    };
    const handleLogoutAPI = (e) => {
        e.preventDefault();
        fetch(`${REACT_APP_SERVER_URL}/logout`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        }).then((response) => {
            if (response.status === 200) {
                navigate("/login");
                return response.json();
            } else {
                console.log("Some error");
            }
        });
    };

    const handleAddWorkspaceClick = (event) => {
        setOpenDialogWorkspace(true);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const openAnchor = Boolean(anchorEl);

    const [activeMenu, setActiveMenu] = useState(existPath);
    const fetchAllWorkspace = (callback) => {
        fetch(`${REACT_APP_SERVER_URL}/fetchAllWorkspaces`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then(async (data) => {
                if (data.data) {
                    const sortedWorkspaces =
                        data.data.length > 0
                            ? data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                            : [];
                    setActiveWorkspace(sortedWorkspaces[0]?.id || null);
                    workspaceContext.handleActiveWorkspace(sortedWorkspaces[0]?.id || null);
                    setActiveWorkspaceName(sortedWorkspaces[0]?.name || "");
                    await workspaceContext.handleWorkspaceList(sortedWorkspaces);
                    await workspaceContext.handleWorkspaceList(sortedWorkspaces);
                    workspaceContext.handleUpdateWorkspace();

                    if (callback && typeof callback === "function") {
                        callback(sortedWorkspaces[0]); // Call the callback function here
                    }
                }
            });
    };
    const handleSaveClick = () => {
        if (!newWorkspaceName && newWorkspaceName.trim().length < 5) {
            setOpenSnackbar({ message: "Invalid WorkSpace Name", type: "error" });
            setNewWorkspaceName("");
            return;
        }
        setHideCreate(true);
        fetch(`${REACT_APP_SERVER_URL}/createworkspace`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                name: newWorkspaceName,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setOpenSnackbar({ message: data.message, type: "success" });
                    workspaceContext.handleUpdateWorkspace();
                    fetchAllWorkspace(handleRedirect);
                } else {
                    setNewWorkspaceName("");
                    setOpenSnackbar({ message: data.message, type: "error" });
                }
                setHideCreate(false);
                setOpenDialogWorkspace(false);
            });
    };
    const handleRedirect = (lastWorkspace) => {
        if (workspaceContext.workspaceList && workspaceContext.workspaceList.length > 0) {
            navigate(`/dashboard/setting?workspace=true&id=${lastWorkspace.id}`);
        } else {
            console.error("No workspaces found.");
        }
    };
    useEffect(() => {
        console.log("Dashboard Calling");
        fetchAllWorkspace();
        workspaceContext.recheckWorkspace();
        ctx.recheckPlanDetails();
        planCtx.getFeatureData();
    }, []);
    const handleWorkspaceClick = (workspaceId, workspaceName) => {
        setActiveWorkspace(workspaceId);
        workspaceContext.handleActiveWorkspace(workspaceId);
        setActiveWorkspaceName(workspaceName);
        setAnchorEl(null);
    };
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    open={open}
                    sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                        boxShadow: "none",
                        marginTop: "-2px",
                    }}
                >
                    <Toolbar
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: "none",
                        }}
                    >
                        {!open && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginLeft: "0!important",
                                    color: "black!important",
                                    ...(open && { display: "none" }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        {open && (
                            <IconButton
                                sx={{
                                    marginRight: 5,
                                }}
                                onClick={() => {
                                    handleDrawerClose();
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                width: "84%",
                                backgroundColor: "white",
                                margin: "5px",
                                overflow: "hidden",
                            }}
                        >
                            {" "}
                            <Button
                                variant="outlined"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                sx={{
                                    mx: 1,
                                    mb: 1,
                                    padding: "8px",
                                    border: "1px solid #e7e7e7",
                                    borderRadius: "6px",
                                    color: "#4F4F4F",
                                    height: "43px",
                                    marginTop: "5px",
                                    fontSize: "14px",
                                    fontFamily: "'HK Nova', sans-serif",
                                    fontWeight: "400",
                                }}
                            >
                                {activeWorkspaceName ? activeWorkspaceName : ""} <ArrowDropDownIcon />
                            </Button>
                            <Menu anchorEl={anchorEl} open={openAnchor} onClose={() => setAnchorEl(null)}>
                                {workspaceContext.workspaceList.length > 0 &&
                                    workspaceContext.workspaceList.map((li, index) => (
                                        <MenuItem
                                            onClick={() => handleWorkspaceClick(li.id, li.name)}
                                            key={index}
                                            sx={{
                                                backgroundColor: activeWorkspace === li.id ? "#f0f0f0" : "transparent",
                                            }}
                                        >
                                            <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                    {li.name}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    color="textSecondary"
                                                    sx={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <Chip
                                                        label={
                                                            workspaceContext.workspaceLimit.role === 2
                                                                ? "owner"
                                                                : "member"
                                                        }
                                                        color="primary"
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: "#e0e0e0",
                                                            color: "#4F4F4F",
                                                        }}
                                                    />
                                                    <FiberManualRecordIcon
                                                        sx={{
                                                            width: "4%",
                                                            marginTop: "-5px",
                                                            marginLeft: "4px",
                                                            marginRight: "4px",
                                                        }}
                                                    />
                                                    {`${li.totalMembers ? li.totalMembers : 0} member`}
                                                </Typography>
                                            </Box>
                                            {activeWorkspace === li.id && (
                                                <CheckIcon
                                                    sx={{
                                                        color: "green",
                                                        marginLeft: 1,
                                                        width: "9%",
                                                        marginTop: -3,
                                                    }}
                                                />
                                            )}
                                        </MenuItem>
                                    ))}

                                <Divider />
                                {workspaceContext.workspaceLimit.role === 2 && (
                                    <MenuItem onClick={() => setAnchorEl(null)}>
                                        <ListItemIcon>
                                            <AddIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Create Workspace" onClick={handleAddWorkspaceClick} />
                                    </MenuItem>
                                )}
                            </Menu>
                            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                                <IconButton
                                    variant="outlined"
                                    onClick={handleProfileMenuOpen}
                                    sx={{
                                        mx: 1,
                                        mb: 1,
                                        padding: "8px",
                                        border: "1px solid #e7e7e7",
                                        borderRadius: "6px",
                                        color: "#4F4F4F",
                                        height: "43px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Avatar
                                            sx={{
                                                bgcolor: "#6E7E85", // Background color (can match the one in the image)
                                                color: "white", // Text color
                                                width: 30, // Adjust size as needed
                                                height: 30, // Adjust size as needed
                                                fontSize: "15px",
                                                fontWeight: "bold", // Make the text bold
                                            }}
                                        >
                                            {avatarText}
                                        </Avatar>
                                        <Typography
                                            sx={{
                                                color: "#4F4F4F",
                                                fontSize: "14px",
                                                fontFamily: "'HK Nova', sans-serif",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {fname}
                                        </Typography>
                                    </Stack>
                                </IconButton>
                                <Box sx={{ float: "right" }}>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={profileMenuOpen}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(profileMenuOpen)}
                                        onClose={handleProfileMenuClose}
                                        style={{ border: "1px solid rgba(0, 0, 0, 0.13)" }}
                                    >
                                        <MenuItem
                                            sx={{
                                                width: "100%",
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                    borderbottom: "1px solid Graytext",
                                                }}
                                            >
                                                <Avatar
                                                    sx={{
                                                        bgcolor: "#6E7E85",
                                                        color: "white",
                                                        width: 40,
                                                        height: 40,
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {avatarText}
                                                </Avatar>

                                                <div style={{ padding: "18px", marginTop: "-20px" }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 600,
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {fname}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {email}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </MenuItem>
                                        <div style={{ width: "auto" }}>
                                            <MenuItem
                                                onClick={() => {
                                                    navigate(`/dashboard/setting?profile=${true}`);
                                                }}
                                            >
                                                <AccountCircleIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginRight: "4px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        ml: "12px",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Profile
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    navigate(`/dashboard/setting?workspace=${true}`);
                                                }}
                                            >
                                                <WorkHistoryIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginRight: "4px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        ml: "12px",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Workspace Setting
                                                </Typography>
                                            </MenuItem>{" "}
                                            <MenuItem
                                                onClick={() => {
                                                    navigate(`/dashboard/setting?email-accounts=${true}`);
                                                }}
                                            >
                                                <SendIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginRight: "4px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        ml: "12px",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Emails Account
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    navigate(`/dashboard/setting?billing=${true}`);
                                                }}
                                            >
                                                <MonetizationOnIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginRight: "4px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        ml: "12px",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Pricing
                                                </Typography>
                                            </MenuItem>{" "}
                                            <Divider />
                                            <MenuItem onClick={handleLogoutAPI}>
                                                <LogoutIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginRight: "4px",
                                                    }}
                                                />{" "}
                                                <Typography
                                                    sx={{
                                                        ml: "12px",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Logout
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                    </Menu>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    open={open}
                    style={{
                        boxshadow:
                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                    }}
                >
                    <DrawerHeader
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            boxshadow:
                                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                        }}
                    >
                        <Box sx={{}}>
                            <Link to="/">
                                <img
                                    src={emailaudit}
                                    alt="img"
                                    style={{
                                        height: "50px",
                                        width: "195px",
                                        top: "0",
                                        boxshadow:
                                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                                        marginLeft: "20px",
                                    }}
                                />
                            </Link>
                        </Box>
                    </DrawerHeader>
                    <Divider />

                    <List sx={{ pl: 2, fontFamily: `'Inter', sans-serif` }}>
                        {planCtx.hideDashboard
                            ? Array.from(new Array(5)).map((_, index) => (
                                  <ListItem
                                      key={index}
                                      sx={{
                                          backgroundColor: "#f2f2f2",
                                          borderRadius: "50px 0px 0px 50px",
                                          width: "100%",
                                          mb: 2,
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <Skeleton variant="circular" width={30} height={30} />
                                      <Skeleton variant="text" width="60%" height={30} sx={{ marginLeft: 2 }} />
                                  </ListItem>
                              ))
                            : planCtx.features.map((link, index) => (
                                  <NavLink
                                      exact
                                      to={link.path ? link.path : "404"}
                                      style={{ textDecoration: "none", color: "#4F4F4F" }}
                                      key={index}
                                  >
                                      <ListItem
                                          onClick={() => {
                                              setActiveMenu(link.path ? link.path : "");
                                          }}
                                          sx={{
                                              backgroundColor: activeMenu.includes(link.path ? link.path : "404")
                                                  ? "#F7F7F9"
                                                  : "none",
                                              borderRadius: "50px 0px 0px 50px",
                                              borderRight: activeMenu.includes(link.path ? link.path : "404")
                                                  ? "4px solid #7953FF"
                                                  : "none",
                                              width: "100%",
                                              mb: 2,
                                              fontSize: "13px",
                                              color: activeMenu.includes(link.path ? link.path : "404")
                                                  ? "blue!important"
                                                  : "none",
                                          }}
                                      >
                                          {link.activeIcon && (
                                              <img
                                                  src={
                                                      activeMenu.includes(link.path ? link.path : "404")
                                                          ? link.activeIcon
                                                          : link.defaultIcon
                                                  }
                                                  alt={link.label}
                                                  style={{ height: "30px" }}
                                              />
                                          )}

                                          <Box sx={{ marginLeft: "12px" }}>{link.label}</Box>
                                      </ListItem>
                                  </NavLink>
                              ))}
                    </List>
                    <Box sx={{ display: "flex", alignItems: "end", height: "100vh", justifyContent: "center" }}>
                        {open && <FreeTrial />}
                    </Box>
                </Drawer>
                {!planCtx.hideDashboard ? (
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                            backgroundColor: "#FBFBFB",
                        }}
                    >
                        <Box sx={{ pt: 1, px: 12 }} key={key}>
                            <Box sx={{ py: 10 }}>
                                <Outlet />
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Container sx={{ pt: 1 }} key={key}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100vh",
                                    px: 2,
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        </Container>
                    </>
                )}
            </Box>
            {openDialogWorkSpace && (
                <Dialog
                    open={openDialogWorkSpace}
                    onClose={() => {
                        setOpenDialogWorkspace(false);
                        setNewWorkspaceName("");
                    }}
                >
                    <DialogTitle sx={{ margin: "10px !important", textAlign: "center" }}>Create workspace</DialogTitle>
                    <DialogContent sx={{ paddingTop: "10px !important" }}>
                        <Box
                            component="form"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                width: "400px",
                            }}
                        >
                            <TextField
                                fullWidth
                                label="Workspace Name"
                                value={newWorkspaceName}
                                onChange={(e) => setNewWorkspaceName(e.target.value)}
                                variant="outlined"
                                error={newWorkspaceName && newWorkspaceName.trim().length < 5}
                                helperText={
                                    newWorkspaceName && newWorkspaceName.trim().length < 5
                                        ? "name must be at least 5 characters long"
                                        : ""
                                }
                            />
                            <Box display="flex" marginTop={2}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setOpenDialogWorkspace(false);
                                        setNewWorkspaceName("");
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!newWorkspaceName || newWorkspaceName.trim().length < 5 || hideCreate}
                                    onClick={handleSaveClick}
                                    sx={{ marginLeft: "10px" }}
                                >
                                    Create
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
            <SnackBar
                open={openSnackbar}
                message={openSnackbar.message}
                type={openSnackbar.type}
                onClose={() => setOpenSnackbar(false)}
            />
        </ThemeProvider>
    );
};

export default DashBoard;
