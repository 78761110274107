import { useContext, useEffect, useState } from "react";
import EmailAccount from "./EmailAccount";
import { Box } from "@mui/material";
import InitialRender from "./InitialRender";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
const EmailAccountHome = ({ secondParam }) => {
    const workctx = useContext(WorkspaceContext);
    const navigate = useNavigate();
    const [EmailAccountTable, setEmailAccountTable] = useState(false);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [key, setKey] = useState(0);
    const handleBackReport = () => {
        navigate(`/dashboard/email-audit-v1?tab=email`, { replace: true });
        fetchSenderEmails();
        setKey((prev) => prev + 1);
    };
    const fetchSenderEmails = () => {
        setLoader(true);
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/campaignFetch?workspace_id=${workctx.activeWorkspace}&report_type=manual_audit`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setData(data.data);
                    setEmailAccountTable(true);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setTimeout(() => setLoader(false), 2000));
    };

    useEffect(() => {
        fetchSenderEmails();
    }, []);

    if (loader) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress /> {/* Show loader until data is fetched */}
            </Box>
        );
    }
    return (
        <Box>
            <Box>
                {!loader && (
                    <Box>
                        {EmailAccountTable ? (
                            <EmailAccount
                                handleBackReport={handleBackReport}
                                key={key}
                                rows={data}
                                secondParam={secondParam}
                            />
                        ) : (
                            <Box>
                                <InitialRender handleBackReport={handleBackReport} rows={data} />
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
export default EmailAccountHome;
