import { useContext, useEffect, useState } from "react";
import CardComponent from "./CardComponent";
import { Box, Typography, Button, TextField, Grid, IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Google from "../../img/icons/google.png";
import Outlook from "../../img/icons/outlook.png";
import imap_smtp from "../../img/icons/imap_smtp.png";
import ImapSmtp from "../../img/icons/imap_smtp.png";
import { useNavigate } from "react-router-dom";
import SnackBar from "../common/SnackBar";
import cookie from "cookiejs";
import axios from "axios";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import { result } from "underscore";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const EmailOnboardDialog = ({ onClose, onboardState, selectedButton, handleImapClose }) => {
    const workctx = useContext(WorkspaceContext);
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(onboardState?.success ? "" : onboardState?.message || "");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(onboardState?.success === false);
    const [smtpPassword, setSmtpPassword] = useState("");
    const [imapPassword, setImapPassword] = useState("");
    const [smtpHost, setSmtpHost] = useState("");
    const [smtpPort, setSmtpPort] = useState("");
    const [imapHost, setImapHost] = useState("");
    const [imapPort, setImapPort] = useState("");
    const [smtpUsername, setSmtpUsername] = useState("");
    const [ImapDialogBox, setIMAPDialogBox] = useState(false);
    const [smtpDialogBox, setSmtpDialogBox] = useState(false);
    const [imapSmtp, setImapSmtp] = useState(false);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    var activeWorkspace = workctx.activeWorkspace ? workctx.activeWorkspace : localStorage.getItem(activeWorkspace);
    useEffect(() => {
        if (!cookie.get("token")) {
            navigate("/login");
        }
    }, []);
    const handleRunProcess = (email) => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/runProcess?sender=${email}`, {
                headers: {
                    Accept: "application/json",
                },
                withCredentials: "include",
            })
            .then((data) => console.log(data))
            .catch((err) => console.log(err));
    };
    const handleCardClick = (cardType) => {
        switch (cardType) {
            case "google":
                googleUserAdd();
                break;
            case "outlook":
                outlookUserAdd();
                console.log("Calling Outlook function");
                break;
            case "imapSmtp":
                handleOpenSmtp();
                break;

            default:
                console.log("Invalid value pressed");
        }
    };

    function handleOpenSmtp() {
        setImapSmtp(true);
        setSmtpDialogBox(true);
    }
    function handleclose() {
        setImapSmtp(false);
        setSmtpDialogBox(false);
        setIMAPDialogBox(false);
    }

    const googleUserAdd = async () => {
        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/auth/google?appType=${selectedButton}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            window.location.href = data.data;
        } catch (error) {
            console.error("Error during Google authentication:", error);
        }
    };

    async function outlookUserAdd() {
        try {
            try {
                const response = await fetch(`${REACT_APP_SERVER_URL}/auth/outlook?appType=${selectedButton}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                window.location.href = data.data;
            } catch (error) {
                console.error("Error during Google authentication:", error);
            }
        } catch (error) {
            console.log(error, "error is coming...");
        }
    }
    function handleSmtpEvent() {
        setLoader(true);
        try {
            fetch(`${REACT_APP_SERVER_URL}/auth/Smtp?appType=${selectedButton}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    mail: smtpUsername,
                    credentials: smtpPassword,
                    smtp_host: smtpHost,
                    smtp_port: smtpPort,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setLoader(false);
                    console.log("data getting from here", data);
                    if (data.success) {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(data.message || data.msg);
                        setSmtpDialogBox(false);
                        setIMAPDialogBox(true);
                        return;
                    }
                    setLoader(false);
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message || data.msg);
                });
        } catch (error) {
            setLoader(false);
            console.error(error, "getting the error...");
        }
    }

    function handleImapEvent() {
        setLoader(true);
        try {
            fetch(`${REACT_APP_SERVER_URL}/auth/Imap`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    mail: smtpUsername,
                    imap_port: imapPort,
                    imap_host: imapHost,
                    credentials: smtpPassword,
                    smtp_host: smtpHost,
                    smtp_port: smtpPort,
                    appType: selectedButton,
                    workSpaceId: activeWorkspace,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("data getting from here", data);
                    if (data.success) {
                        setLoader(false);
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(data.message || data.msg);
                        if (
                            (data.appType.includes("auditTest") || data.appType.includes("email-accounts")) &&
                            data.email
                        ) {
                            handleRunProcess(data.email);
                        }
                        handleImapClose(selectedButton);
                    } else {
                        setLoader(false);
                        setErrorSnackbarOpen(true);
                        setErrorMessage(data.message || data.msg);
                        setImapSmtp(false);
                        setSmtpDialogBox(false);
                        setIMAPDialogBox(false);
                    }
                });
        } catch (error) {
            setLoader(false);
            console.error(error, "getting the error...");
        }
    }

    function handleOpenSmtp() {
        setImapSmtp(true);
        setSmtpDialogBox(true);
    }
    console.log(loader);
    return (
        <>
            {!imapSmtp ? (
                <Box>
                    <Button variant="outlined" color="primary" onClick={onClose}>
                        Back
                    </Button>
                    <Typography sx={{ fontSize: "30px", fontWeight: "700", textAlign: "center" }}>
                        Add a Sender Email to Proceed
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                        To run a test audit, we need to know the sender email address with which you want run a
                        campaign.<br></br> Please add your sender email to continue.{" "}
                    </Typography>
                    <Box display="flex" justifyContent="space-evenly" sx={{ marginTop: "20px" }}>
                        <CardComponent
                            title="Google/Gsuite"
                            typography="Add Your Google / Gsuite accounts here."
                            image={Google}
                            type="google"
                            onCardClick={handleCardClick}
                        />
                        <CardComponent
                            title="OUTLOOK / M365"
                            typography="Add Your Outlook / M365 accounts here."
                            image={Outlook}
                            type="outlook"
                            onCardClick={handleCardClick}
                        />
                        <CardComponent
                            title="IMAP/SMTP"
                            typography="Add Your IMAP / SMTP accounts here."
                            image={ImapSmtp}
                            type="imapSmtp"
                            onCardClick={handleCardClick}
                        />
                    </Box>
                </Box>
            ) : (
                <Box sx={{ my: 3 }}>
                    {imapSmtp && smtpDialogBox && (
                        <>
                            <Box sx={{ mb: 2 }}>
                                <Button color="primary" variant="outlined" onClick={handleclose}>
                                    Back
                                </Button>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                                <img src={imap_smtp} alt="imap_smtp" style={{ float: "left", width: "25px" }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Connect to</Typography>
                                    <Typography>SMTP</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <TextField
                                    fullWidth
                                    label="Enter email which you want to add"
                                    required
                                    value={smtpUsername}
                                    onChange={(e) => setSmtpUsername(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    type="password"
                                    label="Enter SMTP Password"
                                    required
                                    value={smtpPassword}
                                    onChange={(e) => setSmtpPassword(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            label="Enter SMTP Host"
                                            fullWidth
                                            value={smtpHost}
                                            onChange={(e) => setSmtpHost(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            label="Enter SMTP Port"
                                            type="number"
                                            fullWidth
                                            value={smtpPort}
                                            onChange={(e) => setSmtpPort(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        disabled={!smtpUsername || !smtpPassword || !smtpHost || !smtpPort || loader}
                                        onClick={handleSmtpEvent}
                                        variant="contained"
                                        sx={{ textTransform: "none", boxShadow: "none" }}
                                    >
                                        {loader ? <CircularProgress color="inherit" /> : "Next"}
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                    {imapSmtp && ImapDialogBox && (
                        <>
                            <Box sx={{ mb: 2 }}>
                                <IconButton size="small" onClick={handleclose}>
                                    <ArrowBackIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            <>
                                <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                                    <img src={imap_smtp} alt="imap_smtp" style={{ float: "left", width: "25px" }} />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Connect to</Typography>
                                        <Typography>IMAP</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <TextField
                                        fullWidth
                                        label="Enter IMAP Password"
                                        type="password"
                                        required
                                        value={imapPassword}
                                        onChange={(e) => setImapPassword(e.target.value)}
                                        sx={{ mb: 2 }}
                                    />
                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                label="Enter IMAP Host"
                                                fullWidth
                                                value={imapHost}
                                                onChange={(e) => setImapHost(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                label="Enter IMAP Port"
                                                type="number"
                                                fullWidth
                                                value={imapPort}
                                                onChange={(e) => setImapPort(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            onClick={handleImapEvent}
                                            variant="contained"
                                            disabled={loader}
                                            sx={{ textTransform: "none", boxShadow: "none" }}
                                        >
                                            {loader ? <CircularProgress /> : "Next"}
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        </>
                    )}
                </Box>
            )}
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </>
    );
};
export default EmailOnboardDialog;
