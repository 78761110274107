import { useState, useEffect, useContext, useMemo } from "react";
import TableComponent from "../../common/TableComponent";
import { Box, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import FetchReport from "../../AuditReportOverview/FetchCampaignReport";
import CreateReport from "../../AuditReportOverview/CreateCampaignReport";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
const AutomaticTestReport = ({ handleAuditForm }) => {
    const workctx = useContext(WorkspaceContext);
    const [searchText, setSearchText] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [viewReport, setViewReport] = useState(false);
    const [senderData, setSenderData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [noDataCount, setNoDataCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [regenerate, setRegenerate] = useState(false);
    const [key, setKey] = useState(0);
    const columns = [
        { field: "campaignName", headerName: "Campaign Name", sortable: true },
        { field: "inbox", headerName: "Inbox", sortable: true },
        { field: "spam", headerName: "Spam", sortable: true },
        { field: "created", headerName: "Created", sortable: true },
        { field: "action", headerName: "Actions", sortable: false },
    ];
    const Actions = [{ title: "View Report", click: (row) => handleViewReport(row) }];
    const handleViewReport = (row) => {
        const found = senderData.find((item) => item.campaign_name === row.campaignName);
        setSelectedRow(found);
        setViewReport(true);
    };
    const handleBack = () => {
        setRegenerate(false);
        setViewReport(false);
        fetchAutomaticCampaignList();
    };
    const fetchAutomaticCampaignList = () => {
        setLoader(true);
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/campaignFetch?workspace_id=${workctx.activeWorkspace}&report_type=automatic_audit`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSenderData(data.data);
                } else {
                    setLoader(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };
    const filteredSender = useMemo(() => {
        const updatedRows = senderData.map((item) => ({
            campaignName: item.campaign_name,
            inbox: JSON.parse(item.ipt_counts).overallInboxCount,
            spam: JSON.parse(item.ipt_counts).overallSpamCount,
            created: new Date(item.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-"),
        }));

        return updatedRows.filter((campaignList) => {
            const matchesSearch = searchText
                ? campaignList.email.toLowerCase().includes(searchText.toLowerCase())
                : true;
            const matchesDate = selectedDate ? dayjs(selectedDate).format("DD-MM-YYYY") === campaignList.created : true;

            return matchesSearch && matchesDate;
        });
    }, [senderData, searchText, selectedDate]);
    useEffect(() => {
        console.log(filteredSender, "i am filtereds sender");
        if (filteredSender.length > 0) {
            setLoader(false);
        } else {
            setNoDataCount((prev) => {
                const newCount = prev + 1;
                if (newCount >= 3) {
                    setLoader(false);
                }
                return newCount;
            });
        }
    }, [filteredSender]);
    useEffect(() => {
        fetchAutomaticCampaignList();
    }, []);
    const handleRegenerate = () => {
        console.log("regenrateclicked");
        setRegenerate(true);
        setKey((prev) => prev + 1);
    };
    return (
        <Box key={key}>
            {viewReport ? (
                <Box>
                    {regenerate ? (
                        <CreateReport
                            regenerate={regenerate}
                            clientData={selectedRow}
                            handleBackReport={handleBack}
                            actions={[
                                { title: "Campaign List", onClick: handleBack },
                                { title: "Report", onClick: null },
                            ]}
                            handleRegenerate={handleRegenerate}
                            type="automatic_audit"
                        />
                    ) : (
                        <FetchReport
                            handleBack={handleBack}
                            actions={[
                                { title: "Campaign List", onClick: handleBack },
                                { title: "Report", onClick: null },
                            ]}
                            handleRegenerate={handleRegenerate}
                            reportData={selectedRow}
                            type="automatic_audit"
                        />
                    )}
                </Box>
            ) : (
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleAuditForm}>
                            Run Test Audit
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 2,
                            mb: 3,
                            flexWrap: "wrap",
                        }}
                    >
                        <TextField
                            placeholder="Search by Sender Emails"
                            size="small"
                            variant="outlined"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ width: "300px" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            {showDatePicker ? (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={selectedDate}
                                        onChange={(newDate) => setSelectedDate(newDate)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                placeholder="Select Date"
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton>
                                                            <DateRangeIcon />
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            ) : (
                                <IconButton onClick={() => setShowDatePicker(true)}>
                                    <DateRangeIcon />
                                </IconButton>
                            )}
                            {(selectedDate || showDatePicker) && (
                                <IconButton
                                    onClick={() => {
                                        setSelectedDate(null);
                                        setShowDatePicker(false);
                                    }}
                                    color="primary"
                                    title="Clear Date"
                                >
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                    <TableComponent
                        rows={filteredSender}
                        columns={columns}
                        actions={Actions}
                        isLoading={loader}
                        onClick={(row) => handleViewReport(row)}
                    />
                </Box>
            )}
        </Box>
    );
};
export default AutomaticTestReport;
