export const esp = [
    {
        title: "spf",
        label: "SPF",
        status: true,
        content: (
            <div>
                <strong>Congratulations! Your SPF record is valid.</strong>
                <br />
                This means your domain's SPF setup allows receiving mail servers to confirm that emails from your domain
                are sent from authorized IP addresses.
                <br />
                SPF plays a vital role in reducing spam and phishing by verifying that incoming messages align with your
                domain's approved sender list.
                <br />
                Having a valid SPF record ensures better email deliverability and protects your domain's reputation.
            </div>
        ),
    },
    {
        title: "spf",
        label: "SPF",
        status: false,
        content: (
            <div>
                <strong>
                    <b style={{ color: "red" }}>Action Required:</b> Your SPF record validation failed.
                </strong>
                <br />
                This indicates that your SPF setup is either missing or incorrect, and receiving mail servers cannot
                verify the authenticity of your emails.
                <br />
                To fix this, update your DNS settings with a valid SPF record. Include all IP addresses and mail servers
                authorized to send emails on behalf of your domain.
                <br />
                <a
                    href="https://example.com/learn-spf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "blue" }}
                >
                    Learn more about setting up SPF records.
                </a>
            </div>
        ),
    },
    {
        title: "dmarc",
        label: "DMARC",
        status: true,
        content: (
            <div>
                <strong>Congratulations! Your DMARC policy is successfully implemented.</strong>
                <br />
                This means your domain is protected against email spoofing and phishing attacks. DMARC ensures that both
                SPF and DKIM validation are applied and provides reporting on suspicious activity.
                <br />
                Implementing DMARC enhances your domain's security and fosters trust with recipients.
            </div>
        ),
    },
    {
        title: "dmarc",
        label: "DMARC",
        status: false,
        content: (
            <div>
                <strong>
                    <b style={{ color: "red" }}>Action Required:</b> Your DMARC policy validation failed.
                </strong>
                <br />
                This indicates that either SPF or DKIM (or both) validations are failing, and your DMARC policy is not
                working as intended.
                <br />
                To fix this, verify that your SPF and DKIM records are correctly set up and align with your DMARC
                policy. Update your DNS with a valid DMARC record to specify how unauthenticated emails should be
                handled.
                <br />
                <a
                    href="https://example.com/learn-dmarc"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "blue" }}
                >
                    Learn more about setting up DMARC policies.
                </a>
            </div>
        ),
    },
    {
        title: "dkim",
        label: "DKIM",
        status: true,
        content: (
            <div>
                <strong>Congratulations! Your DKIM signature is valid.</strong>
                <br />
                This ensures that your email's integrity is intact, and all signature verification tests have passed
                successfully.
                <br />
                DKIM links your domain name to your email messages, confirming that the content hasn’t been altered
                during transit. This boosts recipient trust and improves email deliverability.
            </div>
        ),
    },
    {
        title: "dkim",
        label: "DKIM",
        status: false,
        content: (
            <div>
                <strong>
                    <b style={{ color: "red" }}>Action Required:</b> our DKIM signature validation failed.
                </strong>
                <br />
                This suggests that your DKIM configuration is missing or misconfigured, preventing verification of your
                email's authenticity.
                <br />
                To fix this, add a DKIM record to your DNS settings. This record will include the public key needed to
                verify the signature of your outgoing messages.
                <br />
                <a
                    href="https://example.com/learn-dkim"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "blue" }}
                >
                    Learn more about setting up DKIM signatures.
                </a>
            </div>
        ),
    },
];
