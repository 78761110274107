import { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import InitialAutomatic from "./InitialAutomaticTest";
import AutomaticTestReport from "./AutomaticTestReport";
import TestAudit from "./TestAudit";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
const AutomaticTest = ({ secondParam }) => {
    const navigate = useNavigate();
    const [showAutomaticTestReport, setShowAutomaticTestReport] = useState(false);
    const [showRunAudit, setShowRunAudit] = useState(secondParam ? true : false);
    const [loader, setLoader] = useState(true);
    const [rows, setRows] = useState([]);
    const workctx = useContext(WorkspaceContext);
    const handleAuditForm = () => {
        setShowRunAudit(true);
    };
    const handleBack = () => {
        navigate(`/dashboard/email-audit-v1?tab=automatic`, { replace: true });
        setShowRunAudit(false);
        fetchAuditData();
    };
    const fetchAuditData = () => {
        setLoader(true);
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/campaignFetch?workspace_id=${workctx.activeWorkspace}&report_type=automatic_audit`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setShowAutomaticTestReport(true);
                    setRows(data.data);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoader(false));
    };
    useEffect(() => {
        fetchAuditData();
    }, []);
    if (loader) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress /> {/* Show loader until data is fetched */}
            </Box>
        );
    }
    return (
        <Box>
            <Box>
                <Box>
                    {showRunAudit ? (
                        <TestAudit handleBack={handleBack} rows={rows} />
                    ) : (
                        <Box>
                            {showAutomaticTestReport ? (
                                <AutomaticTestReport handleAuditForm={handleAuditForm} />
                            ) : (
                                <InitialAutomatic handleAuditForm={handleAuditForm} />
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
export default AutomaticTest;
