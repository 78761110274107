import gsuite from "../../img/images/gsuite.png";
import outlook from "../../img/images/outlook.png";
import google from "../../img/images/google.png";
import m365 from "../../img/images/m365.png";
import aol from "../../img/images/aol.png";
import yahoo from "../../img/images/yahoo.png";
export const serviceProviders = [
    {
        service: "avgGoogleInbox",
        title: "inbox",
        icon: google,
    },
    {
        service: "avgGoogleSpam",
        title: "spam",
        icon: google,
    },
    {
        service: "avgGsuiteInbox",
        title: "inbox",
        icon: gsuite,
    },
    {
        service: "avgGsuiteSpam",
        title: "spam",
        icon: gsuite,
    },
    {
        service: "avgOutlookInbox",
        title: "inbox",
        icon: outlook,
    },
    {
        service: "avgOutlookSpam",
        title: "spam",
        icon: outlook,
    },
    ,
    {
        service: "googleInboxCount",
        title: "inbox",
        icon: google,
    },
    {
        service: "googleSpamCount",
        title: "spam",
        icon: google,
    },
    {
        service: "googleEspInboxCount",
        title: "inbox",
        icon: google,
    },
    {
        service: "googleEspSpamCount",
        title: "spam",
        icon: google,
    },
    {
        service: "gsuiteEspInboxCount",
        title: "inbox",
        icon: gsuite,
    },
    {
        service: "gsuiteEspSpamCount",
        title: "spam",
        icon: gsuite,
    },
    {
        service: "outlookInboxCount",
        title: "inbox",
        icon: outlook,
    },
    {
        service: "outlookSpamCount",
        title: "spam",
        icon: outlook,
    },
    {
        service: "outlookEspInboxCount",
        title: "inbox",
        icon: outlook,
    },
    {
        service: "outlookEspSpamCount",
        title: "spam",
        icon: outlook,
    },
    {
        service: "m365EspInboxCount",
        title: "inbox",
        icon: m365,
    },
    {
        service: "m365EspSpamCount",
        title: "spam",
        icon: m365,
    },
    ,
    {
        service: "avgYahooInbox",
        title: "inbox",
        icon: yahoo,
    },
    {
        service: "avgYahooSpam",
        title: "spam",
        icon: yahoo,
    },
    {
        service: "avgaolInbox",
        title: "inbox",
        icon: aol,
    },
    {
        service: "avgaolSpam",
        title: "spam",
        icon: aol,
    },
];
