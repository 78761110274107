import { Typography, Box } from "@mui/material";
const ManualView = () => {
    return (
        <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                Manual Mode
            </Typography>
            <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                This is the manual view where you can manually audit your emails.
            </Typography>
        </Box>
    );
};
export default ManualView;
