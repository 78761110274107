import { Box, Button, Typography, IconButton } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const InitialAutomatic = ({ handleAuditForm }) => {
    return (
        <Box
            sx={{
                maxWidth: 800,
                margin: "auto",
                padding: "20px",
                textAlign: "center",
            }}
        >
            <IconButton disableRipple sx={{ fontSize: 80, color: "black" }}>
                <MailOutlineIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                Run an Automatic Test Audit
            </Typography>
            <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                Select an already connected sender email account to send a test email and get an instant report
            </Typography>

            <Button
                variant="contained"
                color="info"
                sx={{ mt: 3, textTransform: "none", px: 4 }}
                onClick={handleAuditForm}
            >
                Run Test Audit
            </Button>
        </Box>
    );
};

export default InitialAutomatic;
