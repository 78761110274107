import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import { esp } from "../common/ESP";

const DNSRecords = ({ dnsRecords }) => {
    return (
        <div>
            {Object.keys(dnsRecords).map((key) => {
                const record = esp.find((r) => r.title === key);

                if (!record) return null;

                return (
                    <Accordion key={key}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: "white",
                                padding: "10px 16px",
                                alignItems: "center",
                                borderBottom: "1px solid #e0e0e0",
                                "&:hover": {
                                    backgroundColor: "#f1f1f1",
                                },
                            }}
                        >
                            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                <Typography sx={{ fontWeight: "bold", color: "#333" }}>{record.label}</Typography>
                                <Typography
                                    sx={{
                                        color: dnsRecords[key] ? "#4CAF50" : "#F44336",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {dnsRecords[key] ? "Pass" : "Fail"}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                padding: "16px",
                                backgroundColor: "#fff",
                            }}
                        >
                            {dnsRecords[key] ? (
                                <Typography>{record.content}</Typography>
                            ) : (
                                <Typography>
                                    <strong>Action Required: {record.content}</strong>
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
};

export default DNSRecords;
