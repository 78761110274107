import React, { useContext, useState } from "react";
import { CircularProgress, Box, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import axios from "axios";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GoogleRedirect = (props) => {
    const navigate = useNavigate();
    const workctx = useContext(WorkspaceContext);
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code");
    const appType = queryParameters.get("state");
    const error = queryParameters.get("error");
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const handleRunProcess = (email) => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/runProcess?sender=${email}`, {
                headers: {
                    Accept: "application/json",
                },
                withCredentials: "include",
            })
            .then((data) => console.log(data))
            .catch((err) => console.log(err));
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const activeWorkspace = localStorage.getItem("activeWorkspace");
    React.useEffect(() => {
        if (error !== null) {
            navigate("/dashboard/spreadsheet", { state: { msg: error, status: 201 } });
        } else {
            fetch(
                `${REACT_APP_SERVER_URL}/auth/googleOnboard?code=${code}&appType=${appType}&workSpaceId=${activeWorkspace}&clientName=null`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                    credentials: "include",
                    method: "GET",
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((d) => {
                    if (d?.appType === "email-accounts") {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage("Sender Email Added Successfully! Starting Your test audit now");
                        d.email && handleRunProcess(d.email);
                        navigate("/dashboard/setting?email-accounts=true", { state: d });
                    } else if (d?.appType.includes("email-audit-v1")) {
                        d.success ? setSuccessSnackbarOpen(true) : setErrorSnackbarOpen(true);
                        d.success ? setSuccessMessage(d.message || d.msg) : setErrorMessage(d.message || d.msg);
                        d?.appType.includes("auditTest") && d.email && handleRunProcess(d.email);
                        setTimeout(() => navigate(`/dashboard/${d.appType}`), 2000);
                    } else if (d?.appType.includes("spamwatch")) {
                        d.success ? setSuccessSnackbarOpen(true) : setErrorSnackbarOpen(true);
                        d.success ? setSuccessMessage(d.message || d.msg) : setErrorMessage(d.message || d.msg);
                        setTimeout(() => navigate(`/dashboard/${d.appType}`), 2000);
                    } else {
                        d.success ? setSuccessSnackbarOpen(true) : setErrorSnackbarOpen(true);
                        d.success ? setSuccessMessage(d.message || d.msg) : setErrorMessage(d.message || d.msg);
                        d.email && handleRunProcess(d.email);
                        setTimeout(() => navigate(`/dashboard/integration`), 2000);
                    }
                })
                .catch((error) => console.log(error));
        }
    }, []);
    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
                <CircularProgress />
            </Box>

            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default GoogleRedirect;
