import React, { useEffect, useState, useContext } from "react";
import DashBoard from "./Dashboard";
import { CircularProgress, Box } from "@mui/material";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import SnackBar from "../common/SnackBar";
const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const workCtx = useContext(WorkspaceContext);
    const [loader, setLoader] = useState(true);
    const fetchDashboardData = () => {
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/getDashboardData?workspaceId=${workCtx.activeWorkspace}&fromDate=null&toData=null`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    console.log(data, "dashboardDatataaaa");
                    setDashboardData(data.data);
                } else {
                    setErrorMessage(data.message);
                    setErrorSnackbarOpen(true);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoader(false));
    };
    useEffect(() => {
        fetchDashboardData();
    }, []);
    return (
        <>
            {loader ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <DashBoard dashboardData={dashboardData} />
            )}
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </>
    );
};

export default Dashboard;
