import { useState, useEffect, useRef, useContext } from "react";
import TemplateList from "../NewTemplateList";
import { Button, Box, Grid, Card, CardContent, Typography, Avatar } from "@mui/material";
import SnackBar from "../../common/SnackBar";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DNSOverviewChart from "../DNSOverviewChart";
import ActionComponent from "../ActionComponent";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import { AuthContext } from "../../Provider/AuthProvider";
import AddIntegration from "../AddIntegration";
import BlacklistPanel from "../BlackListPanel";
import DNSRecords from "../DNSRecords";
import SkeletonLoader from "../SeketonLoader";
import ESP from "../ESPDetails";
import CustomBreadCrumbs from "../../common/CustomBreadCrumbs";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Report = ({ handleBackReport, clientData, selectedButton, actions = [], handleRegenerate, regenerate }) => {
    const intervalRef = useRef(null);
    const workctx = useContext(WorkspaceContext);
    const authCtx = useContext(AuthContext);
    const [dnsData, setDNSData] = useState([]);
    const [ipListedZones, setIPListedZones] = useState([]);
    const [domainListedZones, setDomainListedZones] = useState([]);
    const [selectedPanel, setSelectedPanel] = useState("Overview");
    const [iptCounts, setIptCounts] = useState({});
    const [score, setScore] = useState(null);
    const [overview, setOverview] = useState([]);
    const [loader, setLoader] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [progress, setProgress] = useState(0);
    const [randomStringForProcess, setRandomStringForProcess] = useState("");
    const [showEmailIntegration, setShowEmailIntegration] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [auditSender, setAuditSender] = useState("");
    const progressRef = useRef(progress);
    const processRef = useRef(randomStringForProcess);
    const handlePanelSelect = (panel) => {
        setSelectedPanel(panel);
    };

    const handleComponentData = async (results) => {
        console.log(results, results.contentAudit, "contennntttttttt");
        setAuditSender(results?.auditResults[0].email || "");
        await Promise.all([
            handleProcessResult(results.auditResults[0].data, results.scores),
            handleTemplate(results.contentAudit),
            handleIptCount(results.iptCounts.totals),
        ]);
    };
    const handleProcessResult = (results, scores) => {
        const data = results.data;
        console.log(results, "result");
        setScore(Math.floor(scores?.totalScore) || 0);
        const dnsRecord = data.find((item) => item.title === "DNS Records Check");
        setDNSData(dnsRecord?.data || null);
        const ipReputation = data.find((item) => item.title === "IP Reputation Check");
        setIPListedZones(ipReputation?.data?.ipListedZones?.listed || []);
        const domainReputation = data.find((item) => item.title === "Domain Reputation Check");
        setDomainListedZones(domainReputation?.data?.domainListedZones?.listed || []);
    };
    const handleTemplate = (contentAudit) => {
        console.log(contentAudit, "contentAudittttttttttttttt");
        if (contentAudit && contentAudit.length > 0) {
            const mergedContentList = contentAudit.flatMap((audit) => {
                const contentAuditData = audit.data;

                const originalContent = contentAuditData
                    .filter((item) => item.title === "originalContent")
                    .map((item) => item?.data || null);

                const modifiedContent = contentAuditData
                    .filter((item) => item.title === "modifiedContent")
                    .map((item) => item?.data || null);

                return originalContent.map((content, index) => ({
                    ...content,
                    modifiedContent: modifiedContent[index] || null,
                }));
            });

            setTemplateList(mergedContentList);
        } else {
            setShowEmailIntegration(true);
        }
    };
    const handleIptCount = (ipt) => {
        setOverview([
            { title: "Inbox Count", count: parseInt(ipt?.totalInboxCount, 10) || 0 },
            { title: "Spam Count", count: parseInt(ipt?.totalSpamCount, 10) || 0 },
        ]);
        setIptCounts(ipt);
    };
    const generateRandomString = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };
    const handleSenderInteregation = (email) => {
        console.log("handle Sender intergation", email);
        fetch(`${process.env.REACT_APP_SERVER_URL}/runProcess?sender=${email}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => console.log(data))
            .catch((err) => console.log(err));
    };
    const createAuditAutomationData = async () => {
        var randomString;
        randomString = generateRandomString(10);
        setRandomStringForProcess(randomString);
        try {
            setLoader(true);
            const url = regenerate
                ? `${REACT_APP_SERVER_URL}/refreshRegenrateReport`
                : `${REACT_APP_SERVER_URL}/senderAddedCreateRecord`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    sender: clientData.email,
                    process_code: randomString,
                    type: "audit_report",
                }),
            });

            const data = await response.json();
            if (data.success) {
                handleComponentData(data);
                if (!regenerate) {
                    handleSenderInteregation(data?.auditResults[0].email || "");
                }
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            setLoader(false);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);
            setTimeout(() => handleBackReport(), 2000);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        createAuditAutomationData();
    }, []);
    useEffect(() => {
        if (randomStringForProcess) {
            const initialTimeout = setTimeout(() => {
                getAuditProgress();

                intervalRef.current = setInterval(() => {
                    getAuditProgress();
                }, 5000);
            }, 10000);

            return () => {
                clearTimeout(initialTimeout);
                clearInterval(intervalRef.current);
            };
        }
    }, [randomStringForProcess]);

    const getAuditProgress = async () => {
        await fetch(`${process.env.REACT_APP_SERVER_URL}/fetchProcessResult?process_code=${randomStringForProcess}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success && parseInt(data.data.process_status, 10)) {
                    setProgress(parseInt(data.data.process_status, 10));
                }

                if (parseInt(data.data.process_status, 10) >= 100) {
                    clearInterval(intervalRef.current);
                    setProgress(parseInt(data.data.process_status, 10));
                    setLoader(false);
                }
            })
            .catch((err) => {
                console.error("Connection refused or network error");
                setLoader(false);
                setSnackbarMessage("Connection refused or network error");
                setSnackbarOpen(true);
                setTimeout(() => handleBackReport(), 2000);
            });
    };
    const dummyData = [
        {
            icon: <RocketLaunchIcon />,
            value: "--",
            description: "Per day volume",
        },
        {
            icon: <ChatBubbleIcon />,
            value: "--",
            description: "Per day warmup volume",
        },
    ];
    function LinearProgressWithLabel(props) {
        return (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        {...props}
                        style={{
                            height: 10, // **Height of the progress bar** (changed)

                            borderRadius: 5, // **Rounded corners** (changed)
                            "& .MuiLinearProgress-bar": {
                                borderRadius: 5, // **Rounded corners of the progress bar fill** (changed)
                            },
                            color: "#fff",
                        }}
                    />
                </Box>
                <Box style={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };
    const handleImapClose = () => {
        createAuditAutomationData();
    };
    useEffect(() => {
        progressRef.current = progress;
        processRef.current = randomStringForProcess;
    }, [progress, randomStringForProcess]);
    console.log(selectedButton, "selected");
    return (
        <Box>
            <>
                <Grid container alignItems="center" justifyContent="space-between">
                    {/* Breadcrumbs on the left */}
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <CustomBreadCrumbs actions={actions} />
                        </Grid>{" "}
                        <Grid item>
                            {handleRegenerate && !loader && (
                                <Button variant="contained" color="primary" onClick={handleRegenerate}>
                                    Regenerate
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {loader || Object.keys(dnsData).length <= 0 ? (
                    <div>
                        {" "}
                        <Box style={{ backgroundColor: "#F5F5F5", padding: "20px", borderRadius: "10px" }}>
                            <Box>
                                <Typography>Processing...</Typography>
                            </Box>
                            <LinearProgressWithLabel value={progress} />
                        </Box>
                        <SkeletonLoader />
                    </div>
                ) : (
                    <Box className="container_box">
                        <Grid container spacing={2} columns={16}>
                            <Grid item xs={3} sm={8} md={4}>
                                <Card
                                    style={{
                                        width: "100%",
                                        mb: 2,
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                        color: "white",
                                        backgroundColor: "#0884e2",
                                    }}
                                >
                                    <CardContent style={{ textAlign: "center" }}>
                                        <Typography variant="h4">High Score</Typography>
                                        <Typography variant="h2">{score}</Typography>
                                        <Typography variant="subtitle1">out of 100</Typography>
                                    </CardContent>
                                    <Typography
                                        style={{
                                            margin: "10px",
                                            padding: "10px",
                                            backgroundColor: "#9EC4DF",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        The score is calculated directly from these metrics: Inbox rate, Domain health,
                                        and IP insights
                                    </Typography>
                                </Card>
                            </Grid>
                            <ESP iptCounts={iptCounts} />
                            <Grid item xs={3} sm={8} md={4}>
                                {dummyData.map((data, index) => (
                                    <Card
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: "120px",
                                            padding: "20px",
                                            marginBottom: "20px",
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            "&:hover": { backgroundColor: "#F7F7F9" },
                                        }}
                                    >
                                        <Avatar style={{ bgcolor: "#E0E0E0", mr: 2 }}>{data.icon}</Avatar>
                                        <CardContent style={{ flexGrow: 1, textAlign: "center" }}>
                                            <Typography variant="h5" component="div">
                                                {data.value}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                {data.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Grid>
                        <Box style={{ marginTop: "10px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <ActionComponent onSelect={handlePanelSelect} />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    {selectedPanel === "Blacklist" && (
                                        <BlacklistPanel
                                            domainListedZones={domainListedZones}
                                            ipListedZones={ipListedZones}
                                        />
                                    )}
                                    {selectedPanel === "DNS Reputation" && <DNSRecords dnsRecords={dnsData} />}
                                    {selectedPanel === "Overview" && <DNSOverviewChart inboxSpamCount={overview} />}
                                    {selectedPanel === "TemplateList" && (
                                        <Box>
                                            {showEmailIntegration ? (
                                                <AddIntegration
                                                    handleImapClose={handleImapClose}
                                                    selectedButton={selectedButton}
                                                    senderEmail={clientData?.email || auditSender}
                                                />
                                            ) : (
                                                <TemplateList templates={templateList} />
                                            )}
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </>
            <SnackBar
                open={snackbarOpen}
                message={snackbarMessage}
                type="error"
                onClose={() => setSnackbarOpen(false)}
            />
        </Box>
    );
};
export default Report;
