import React from "react";
import { Typography, Paper, Grid, List, ListItem, ListItemText } from "@mui/material";
const BlacklistPanel = ({ domainListedZones, ipListedZones }) => {
    console.log(domainListedZones, ipListedZones);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        padding: "20px",
                        border: "1px solid #f1f1f1",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        maxHeight: "280px",
                        overflowY: "auto",
                        boxShadow: "none !important",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#24a0ed",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}
                    >
                        Domain Blacklist
                    </Typography>
                    <List>
                        {domainListedZones.length === 0 ? (
                            <ListItem>
                                <ListItemText primary="No data found" sx={{ textAlign: "center" }} />
                            </ListItem>
                        ) : (
                            domainListedZones.map((data, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid #ddd",
                                        borderRadius: "5px",
                                        marginBottom: "2px",
                                        "&:hover": {
                                            backgroundColor: "#e0f2f1",
                                        },
                                    }}
                                >
                                    <ListItemText primary={data.zone} sx={{ textAlign: "center" }} />
                                    <a
                                        className="custom-button"
                                        href={`http://${data.url}/`} // External URL
                                        target="_blank" // Opens the link in a new tab
                                        rel="noopener noreferrer" // Security best practice when opening in a new tab
                                        style={{
                                            color: "#fff",
                                            textDecoration: "none",
                                        }}
                                    >
                                        Delist
                                    </a>
                                </ListItem>
                            ))
                        )}
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        padding: "20px",
                        border: "1px solid #f1f1f1",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        maxHeight: "280px",
                        overflowY: "auto",
                        boxShadow: "none !important",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#24a0ed",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}
                    >
                        IP Blacklist
                    </Typography>
                    <List>
                        {ipListedZones.length === 0 ? (
                            <ListItem>
                                <ListItemText primary="No data found" sx={{ textAlign: "center" }} />
                            </ListItem>
                        ) : (
                            ipListedZones.map((data, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid #ddd",
                                        borderRadius: "5px",
                                        marginBottom: "2px",
                                        "&:hover": {
                                            backgroundColor: "#e0f2f1",
                                        },
                                    }}
                                >
                                    <ListItemText primary={data.zone} sx={{ textAlign: "center" }} />
                                    <a
                                        className="custom-button"
                                        href={`http://${data.url}/`} // External URL
                                        target="_blank" // Opens the link in a new tab
                                        rel="noopener noreferrer" // Security best practice when opening in a new tab
                                        style={{
                                            color: "#fff",
                                            textDecoration: "none",
                                        }}
                                    >
                                        Delist
                                    </a>
                                </ListItem>
                            ))
                        )}
                    </List>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default BlacklistPanel;
