import { createContext, useEffect, useMemo } from "react";
import { io } from "socket.io-client";
export const SocketContext = createContext();
const SocketProvider = ({ children }) => {
    const socket = useMemo(() => {
        const socketUrl = process.env.REACT_APP_SOCKET_URL || "http://localhost:3000";
        console.log(socketUrl, "socketUrl to connect...");
        return io(socketUrl, { transports: ["websocket"] });
    }, []);
    useEffect(() => {
        // Log connection status
        socket.on("connect", () => {
            console.log(`[Socket.IO] Connected: Socket ID = ${socket.id}`);
        });
        // Log disconnection
        socket.on("disconnect", (reason) => {
            console.log(`[Socket.IO] Disconnected. Reason: ${reason}`);
        });
        // Log connection errors
        socket.on("connect_error", (error) => {
            console.error(`[Socket.IO] Connection Error: ${error.message}`);
        });
        // Log reconnection attempts
        socket.on("reconnect_attempt", (attempt) => {
            console.log(`[Socket.IO] Reconnection attempt #${attempt}`);
        });
        // Log when reconnection is successful
        socket.on("reconnect", (attempt) => {
            console.log(`[Socket.IO] Successfully reconnected after ${attempt} attempts`);
        });
        // Log when reconnection fails permanently
        socket.on("reconnect_failed", () => {
            console.error("[Socket.IO] Reconnection failed.");
        });
        // Cleanup on unmount
        return () => {
            socket.disconnect();
            console.log("[Socket.IO] Socket disconnected on cleanup.");
        };
    }, [socket]);
    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
export default SocketProvider;
