import React, { useState, useEffect } from "react";
import {
    Checkbox,
    TextField,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    ListItemText,
    Typography,
    IconButton,
    FormControlLabel,
    Dialog,
    DialogContent,
} from "@mui/material";
import HtmlPreview from "../../../AuditReportOverview/HtmlPreview";
import CodeIcon from "@mui/icons-material/Code";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailOnboard from "../../../EmailOnBoard/Onboard";
import CustomBreadCrumbs from "../../../common/CustomBreadCrumbs";
import APIScheduler from "./Scheduled";
const AuditForm = ({ senderList, onSubmit, handleBack, handleImapClose, rows }) => {
    const [clientData, setClientData] = useState({
        campaignName: "",
        sender: [],
        subject: "",
        body: "",
    });
    const [errors, setErrors] = useState({
        campaignName: "",
        sender: "",
        subject: "",
        body: "",
    });
    const [showPreview, setShowPreview] = useState(false);
    const [showEmailIntegration, setShowEmailIntegration] = useState(false);
    const [selectedDays, setSelectedDays] = useState({
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
        Saturday: 0,
        Sunday: 0,
    });
    const isSubmitDisabled =
        !clientData.subject.trim() ||
        !clientData.body.trim() ||
        clientData.sender.length === 0 ||
        !Object.values(selectedDays).some(Boolean);

    const validateField = (name, value) => {
        if (name === "campaignName") {
            if (value.trim() === "") return "Campaign Name is required";
            if (rows.some((row) => row.campaign_name === value)) {
                return "Campaign Name already exists";
            }
        }
        if (name === "subject" && value.trim() === "") return "Subject is required";
        if (name === "body" && value.trim() === "") return "Body is required";
        if (name === "sender" && value.length === 0) return "At least one email must be selected";
        return "";
    };
    console.log(rows, "auditForm");
    const handleChange = (data, name) => {
        const error = validateField(name, data);
        setErrors((prev) => ({ ...prev, [name]: error }));

        setClientData((prev) => ({ ...prev, [name]: data }));
    };

    const handleAddNewSender = () => {
        setShowEmailIntegration(true);
    };
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const handleChangeDays = (event) => {
        setSelectedDays({
            ...selectedDays,
            [event.target.name]: event.target.checked ? 1 : 0,
        });
    };
    const handleSubmit = async () => {
        const errors = {
            campaignName: validateField("campaignName", clientData.campaignName),
            subject: validateField("subject", clientData.subject),
            body: validateField("body", clientData.body),
            sender: validateField("sender", clientData.sender),
        };

        if (Object.values(errors).some((error) => error)) {
            setErrors(errors); // Show errors if any
        } else {
            onSubmit({ ...clientData, selectedDays: selectedDays }); // Submit the data
        }
    };
    return (
        <>
            {showEmailIntegration && (
                <Dialog open={showEmailIntegration} maxWidth="xl" onClose={() => setShowEmailIntegration(false)}>
                    <DialogContent>
                        <EmailOnboard
                            onClose={() => setShowEmailIntegration(false)}
                            selectedButton="email-audit-v1?tab=automatic,auditTest=true"
                            handleImapClose={handleImapClose}
                        />
                    </DialogContent>
                </Dialog>
            )}

            <Box>
                <CustomBreadCrumbs
                    actions={[
                        { title: "Campaign List", onClick: handleBack },
                        { title: "Audit Form", onClick: null },
                    ]}
                />
                <Box marginBottom={2}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h5" fontWeight={600}>
                            Run Your Test Audit
                        </Typography>
                        <Typography>
                            Fill in the details below to perform a comprehensive audit of your email.
                        </Typography>
                    </div>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 3,
                            marginTop: 3,
                        }}
                    >
                        {" "}
                        <TextField
                            fullWidth
                            label="Campaign"
                            value={clientData.campaignName}
                            onChange={(e) => handleChange(e.target.value, "campaignName")}
                            margin="normal"
                            variant="outlined"
                            error={!!errors.campaignName}
                            helperText={errors.campaignName}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="from-label">Sender Email</InputLabel>
                            <Select
                                labelId="from-label"
                                value={clientData.sender}
                                onChange={(e) => handleChange(e.target.value, "sender")}
                                input={<OutlinedInput label="Sender Email" />}
                            >
                                {senderList && senderList.length > 0 ? (
                                    senderList.map((list) => (
                                        <MenuItem key={list.id} value={list.mail}>
                                            {list.mail}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>
                                        <ListItemText primary="No Data Found" />
                                    </MenuItem>
                                )}
                            </Select>
                            {errors.sender && <Box color="error.main">{errors.sender}</Box>}
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}>
                        <Typography
                            onClick={handleAddNewSender} // Function to handle adding a new sender
                            sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontSize: "0.9rem",
                            }}
                        >
                            + Add new sender
                        </Typography>
                    </Box>
                    <Typography variant="h6" mt={3}>
                        Email Template
                    </Typography>
                    <TextField
                        fullWidth
                        label="Enter email subject here"
                        value={clientData.subject}
                        onChange={(e) => handleChange(e.target.value, "subject")}
                        margin="normal"
                        variant="outlined"
                        error={!!errors.subject}
                        helperText={errors.subject}
                    />
                    <Box
                        sx={{
                            position: "relative",
                        }}
                    >
                        {!showPreview ? (
                            <TextField
                                fullWidth
                                label="Enter email message here"
                                value={clientData.body}
                                onChange={(e) => handleChange(e.target.value, "body")}
                                margin="normal"
                                variant="outlined"
                                multiline
                                rows={10}
                                error={!!errors.body}
                                helperText={errors.body}
                            />
                        ) : clientData.body.trim() ? (
                            <HtmlPreview htmlContent={clientData.body} />
                        ) : (
                            <Box
                                mt={2}
                                color="text.secondary"
                                sx={{ textAlign: "center", border: "1px solid #ccc" }}
                                height="300px"
                            >
                                No content to preview.
                            </Box>
                        )}
                        <IconButton
                            onClick={() => setShowPreview(!showPreview)}
                            sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                zIndex: 1,
                                color: showPreview ? "primary.main" : "text.secondary",
                            }}
                        >
                            {showPreview ? <VisibilityOffIcon /> : <CodeIcon />}
                        </IconButton>
                    </Box>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Schedule
                        </Typography>
                        <Box display="flex" gap={2} flexWrap="wrap">
                            {daysOfWeek.map((day) => (
                                <FormControlLabel
                                    key={day}
                                    control={
                                        <Checkbox checked={selectedDays[day]} onChange={handleChangeDays} name={day} />
                                    }
                                    label={day}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
                        Run Test Audit
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AuditForm;
