import React from "react";
import { Doughnut } from "react-chartjs-2";

const EmailStatsChart = ({ inboxSpamCount }) => {
    const inboxCount = inboxSpamCount.find((data) => data.title === "Inbox Count")?.count || 0;
    const spamCount = inboxSpamCount.find((data) => data.title === "Spam Count")?.count || 0;
    const totalEmails = inboxCount + spamCount;

    const inboxPercentage = totalEmails === 0 ? 0 : ((inboxCount / totalEmails) * 100).toFixed(2);
    const spamPercentage = totalEmails === 0 ? 0 : ((spamCount / totalEmails) * 100).toFixed(2);

    const data = {
        labels: ["Inbox", "Spam"],
        datasets: [
            {
                data: totalEmails === 0 ? [1] : [inboxCount, spamCount], // Show a fallback dataset if no emails
                backgroundColor: totalEmails === 0 ? ["#E0E0E0"] : ["#36A2EB", "#FF6384"],
                hoverBackgroundColor: totalEmails === 0 ? ["#B0B0B0"] : ["#36A2EB", "#FF6384"],
            },
        ],
    };

    const options = {
        cutout: "80%",
        plugins: {
            legend: {
                display: true,
                position: "bottom",
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        if (totalEmails === 0) return "No data available";
                        return `${context.label}: ${context.raw}`;
                    },
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div
            style={{
                backgroundColor: "#F8F9FA",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                margin: "20px auto",
            }}
        >
            <h5
                style={{
                    color: "#333",
                    fontSize: "18px",
                    fontWeight: "600",
                    marginBottom: "20px",
                    textAlign: "center",
                }}
            >
                Overview Status
            </h5>
            <div style={{ textAlign: "center" }}>
                {totalEmails === 0 ? (
                    <p style={{ color: "#999", fontSize: "16px", textAlign: "center" }}>No email data available.</p>
                ) : (
                    <Doughnut data={data} options={options} width={300} />
                )}
            </div>
            <div style={{ textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                <div>
                    <p
                        style={{
                            color: "#555",
                            fontSize: "16px",
                            marginBottom: "10px",
                        }}
                    >
                        Inbox Count:
                    </p>
                    <p
                        style={{
                            color: "#555",
                            fontSize: "16px",
                        }}
                    >
                        Spam Count:
                    </p>
                </div>
                <div>
                    <p>
                        <strong>{inboxPercentage}%</strong>
                    </p>
                    <p>
                        <strong>{spamPercentage}%</strong>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmailStatsChart;
